import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHasToken, setLogin } from '../actions/actions';
import { setting } from '../config';

const isTokenExpired = login => {
    return new Date().getTime() - login['login_at'] > setting.ttl;
};
/**
 * customize for token
 */
// export const useGetToken = () => {
//     let hasToken = false;
//     const login = JSON.parse(localStorage.getItem('login') || '{}');

//     console.log(login);
//     /** if has token */
//     if (Object.keys(login).length > 0 && login.token) {
//         if (isTokenExpired(login)) {
//             localStorage.removeItem('login');
//         } else {
//             hasToken = true;
//         }
//     }
//     console.log(`hasToken: ${hasToken}`);
//     return {
//         hasToken,
//     };
// };

export const useGetToken = () => {
    const dispatch = useDispatch();
    let login = useSelector(state => state.login);
    let localLogin;

    /** redux有没有login状态 */
    if (!login) {
        localLogin = localStorage.getItem('login');
        try {
            localLogin = !!localLogin && JSON.parse(localLogin);
        } catch (err) {
            console.error(err);
        }
        /** 本地有没有login状态 */
        if (localLogin) {
            dispatch(setLogin(localLogin));
            login = localLogin;
        }
    }

    const checkIsHasToken = () => {
        /** if has token */
        if (login && login.token) {
            /** token是否过期 */
            if (isTokenExpired(login)) {
                window.location.href = 'login';
                localStorage.removeItem('login');
                dispatch(setHasToken(false));
            } else {
                dispatch(setHasToken(true));
            }
        }
    };

    useEffect(() => {
        /** 查询token是否存在，给redux赋值 */
        checkIsHasToken();
    });
};
