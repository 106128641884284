import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import styles from './login.module.scss';
import axios from '../../utils/o-axios';
import { hideLoading, showLoading, showToast } from '../../utils/toast';
import misc from '../../services/misc';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import AgreementDialog from '../../components/AgreementDialog/AgreementDialog';
import { checkMobile } from '../../services/user';
import HeaderLogo from '../../statics/header-logo.png';
import SelectRectangle from '../../statics/select-rectangle.png';
import SwitchLangue from '../../components/SwitchLangue/SwitchLangue';

const Login = () => {
    // const { messages } = useIntl();
    const [tel, setTel] = useState(null);
    const [isShowAgreementDialog, setIsShowAgreementDialog] = useState(false);
    const [isShowSelectDialog, setIsShowSelectDialog] = useState(false);
    const [nextStepClicked, setNextStepClicked] = useState(false);
    const [countryCode, setCountryCode] = useState('+86');
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const getCountryCode = () => {
            return axios.post('/v1/misc/country_code').then(res => {
                setCountryCodeList(res.data);

                setCountryCode(res?.data?.[0]?.code ?? '+86');
            });
        };

        const init = () => {
            return Promise.all([getCountryCode()]);
        };
        showLoading();

        init()
            .then(res => {})
            .catch(err => {})
            .finally(() => {
                setIsReady(true);
                hideLoading();
            });
    }, []);

    const history = useHistory();

    const onChangeTel = event => {
        let telNumber = String(event.target.value).trim();
        if (telNumber.startsWith('+852')) {
            telNumber = telNumber.slice(4);
        } else if (telNumber.startsWith('+86')) {
            telNumber = telNumber.slice(3);
        } else if (telNumber.startsWith('+')) {
            telNumber = telNumber.slice(1);
        }
        setTel(telNumber);
    };

    const onClickNextStep = () => {
        if (!tel) {
            return;
        }
        const fullTel = `${countryCode}${tel}`;
        setNextStepClicked(true);
        checkMobile({
            mobile_number: fullTel,
        })
            .then(res => {
                /** 已经注册过，不再弹协议弹窗 */
                if (res.data.is_reged === 1) {
                    __getSmsCode();
                } else {
                    setIsShowAgreementDialog(true);
                }
            })
            .catch(err => {
                showToast(err.msg);
            });
    };

    const onChangeSelectCountryCode = value => {
        setCountryCode(value);
    };

    const onClickAgreementBtn = () => {
        __getSmsCode();
    };

    const __getSmsCode = () => {
        showLoading();
        const fullTel = `${countryCode}${tel}`;

        misc.getSmsCode({ mobile_number: fullTel })
            .then(() => {
                sessionStorage.removeItem('loginState');
                hideLoading();
                history.push(`/verification/${countryCode}/${tel}`);
            })
            .catch(err => {
                showToast(err.msg);
            })
            .finally(() => setIsShowAgreementDialog(false));
    };

    return (
        <>
            <BasicWrapper>
                {isReady && (
                    <>
                        <SwitchLangue />
                        <div className={styles['login-wrap']}>
                            <img src={HeaderLogo} className={styles['login-logo']} />
                            <h2 className={styles['welcome-word']}>
                                <FormattedMessage id="login.hello"></FormattedMessage>
                            </h2>
                            <h2 className={styles['welcome-words']}>
                                <FormattedMessage id="login.welcome"></FormattedMessage>
                            </h2>
                            <div className={styles['underlane']} />
                            <div className={styles['tips']}>
                                <FormattedMessage id="login.inputPhoneNumber"></FormattedMessage>
                            </div>
                            <div className={styles['tel']}>
                                <div
                                    className={styles['select']}
                                    onClick={() => setIsShowSelectDialog(true)}
                                >
                                    <span className={styles['country-code']}>{countryCode}</span>
                                    <img src={SelectRectangle} alt="" />
                                </div>
                                {/* <Input
                                    type="number"
                                    className={styles['input']}
                                    onChange={onChangeTel}
                                    value={tel}
                                    autoComplete="tel"
                                /> */}
                                <input
                                    className={styles.input}
                                    type="tel"
                                    autoComplete="tel"
                                    value={tel}
                                    onChange={onChangeTel}
                                />
                            </div>

                            <button
                                className={`${styles['next-step-btn']} ${
                                    !tel && styles['next-step-btn-disable']
                                }`}
                                onClick={onClickNextStep}
                            >
                                <FormattedMessage id="next"></FormattedMessage>
                            </button>
                            <div className={styles['check']}>
                                <span className={styles['check-text']}>
                                    <FormattedMessage id="check.title"></FormattedMessage>
                                </span>
                            </div>
                            <div className={styles['clause-wrapper']}>
                                <FormattedMessage id="check.tip1"></FormattedMessage>
                                <span
                                    className={styles['show-clause']}
                                    onClick={() => setIsShowAgreementDialog(true)}
                                >
                                    <FormattedMessage id="check.tip2"></FormattedMessage>
                                </span>
                                <FormattedMessage id="check.tip3"></FormattedMessage>
                            </div>
                        </div>
                        <Drawer
                            placement={'bottom'}
                            closable={false}
                            onClose={() => setIsShowSelectDialog(false)}
                            visible={isShowSelectDialog}
                            className={styles['drawer']}
                        >
                            <div
                                className={styles['header']}
                                onTouchMove={() => setIsShowSelectDialog(false)}
                            >
                                <div className={styles['line']}></div>
                            </div>
                            <div className={styles['title']}>选择手机号码归属地</div>
                            <div className={styles['container']}>
                                {countryCodeList.map(item => (
                                    <div
                                        key={item.code}
                                        className={`${styles['option']} ${
                                            countryCode === item.code && styles['select']
                                        }`}
                                        onClick={() => {
                                            setCountryCode(item.code);
                                            setIsShowSelectDialog(false);
                                        }}
                                    >
                                        <span>{item.code}</span>
                                        <span>{item.comment}</span>
                                    </div>
                                ))}
                            </div>
                        </Drawer>
                        <div className={styles['select-dialog']}></div>
                        {isShowAgreementDialog && (
                            <AgreementDialog
                                isShowAgreementDialog={isShowAgreementDialog}
                                closeAgreementDialog={() => setIsShowAgreementDialog(false)}
                                nextStepClicked={nextStepClicked}
                                onClickAgreementBtn={onClickAgreementBtn}
                                id={1}
                            />
                        )}
                    </>
                )}
            </BasicWrapper>
        </>
    );
};
export default Login;
