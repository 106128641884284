import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './schedule.module.scss';
import Header from '../../layouts/header/header';
import { Steps } from 'antd';
import openact from '../../services/openact';
import { showToast } from '../../utils/toast';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { useIntl, FormattedMessage } from 'react-intl';
import qs from 'qs';
import ProgressHeader from '../../components/ProgressHeader/ProgressHeader';
import SwitchLangue from '../../components/SwitchLangue/SwitchLangue';
import { useSelector } from 'react-redux';

const { Step } = Steps;

const Schedule = ({ history, location }) => {
    const queryStr = location.search.slice(1);
    const query = qs.parse(queryStr);
    const lang = useSelector(state => state.config.lang);
    const intl = useIntl();
    const errorToast = intl.formatMessage({ id: 'errorToast' });
    const [stepDetails, setStepDetails] = useState([]);

    useEffect(() => {
        /** f=1代表从短信链接跳转进入，要清除缓存重新登录，避免一人多户注册，token还是之前手机号的token */
        if (query.f === '1') {
            localStorage.removeItem('login');
            history.replace('/login');
        } else {
            openact
                .getStatus()
                .then(res => {
                    setStepDetails(res.data.step_details);
                    /** 未完成 */
                    if (res.data?.is_completed === 0) {
                        const rejectedIndex = res.data?.step_details?.findIndex(v => {
                            return v.rejected === 1;
                        });
                        /** 如果存在被驳回的，就停止重定向 */
                        if (rejectedIndex >= 0) {
                            return;
                        }
                        /** 第一个未完成的 */
                        const index = res.data?.step_details?.findIndex(v => {
                            return v.is_completed === 0;
                        });
                        /** 如果是需要填表的step，导航到表单页 */
                        if (index >= 0 && res.data.step_details?.[index]?.need_form === 1) {
                            history.replace(
                                `/openact/${res.data.step_details?.[index]?.next_form_id}/${res.data.step_details?.[index]?.step_id}`,
                            );
                        }
                    }
                })
                .catch(err => {
                    showToast(err.msg);
                });
        }
    }, [lang]);

    const onClickFillAgain = rejectedStepId => {
        // console.log('stepDetails', stepDetails);
        // console.log('rejectedStepId', rejectedStepId);
        /**
         * 找到被驳回的rejectedStep对应的step_detail
         */
        const index = stepDetails?.findIndex(v => {
            return v.step_id === rejectedStepId;
        });
        if (index >= 0) {
            history.replace(
                `/openact/${stepDetails?.[index]?.next_form_id}/${stepDetails?.[index]?.step_id}`,
            );
        } else {
            showToast(errorToast);
        }
    };

    const donePercent = 98;

    return (
        <BasicWrapper>
            <SwitchLangue />
            {/* <Header title={messages['title.openact']}></Header> */}
            <div className={styles.wrapper}>
                <ProgressHeader donePercent={donePercent} />
                <Steps direction="vertical" className={styles.steps}>
                    {stepDetails.map(v => (
                        <Step
                            key={v.title}
                            title={v.title}
                            description={
                                <div>
                                    <span className={styles['desc']}>{v.comment}</span>
                                    {v.rejected === 1 && !!v.rejected_step_id && (
                                        <span
                                            className={styles['fill-again']}
                                            onClick={() => onClickFillAgain(v.rejected_step_id)}
                                        >
                                            <FormattedMessage id="schedule.tip"></FormattedMessage>
                                        </span>
                                    )}
                                </div>
                            }
                            status={
                                v.is_completed === 1
                                    ? 'finish'
                                    : v.rejected === 1
                                    ? 'error'
                                    : 'wait'
                            }
                        ></Step>
                    ))}
                </Steps>
            </div>
        </BasicWrapper>
    );
};
export default withRouter(Schedule);
