import { SET_HAS_TOKEN } from '../../constants/constants';

function hasToken(state = false, action) {
    const { type, hasToken } = action;

    if (type === SET_HAS_TOKEN) {
        return hasToken;
    } else {
        return state;
    }
}

export default hasToken;
