// zh_CN.js
const zh_CN = {
    next: '下一步',
    previous: '上一步',
    upload: '上传',
    sample: '示例',
    enter: '请输入',
    select: '请选择',
    selected: '已选择',
    errorToast: '出错了',
    confirm: '确定',
    search: '搜索',

    'signDoc.next': '我已下载资料文件',

    'login.hello': '您好，',
    'login.welcome': '欢迎来到华兴资本财富',
    'login.inputPhoneNumber': '请输入手机号码',
    'login.info': '请勾选底部条款及细则',

    'title.openact': '华兴资本财富开户',

    'verification.tip1': '请输入验证码',
    'verification.tip2': '验证码已发送至',
    'verification.tip3': '未收到验证码？ {second}秒后重发',
    'verification.tip4': '重新发送验证码',

    'invitation.title': '请输入邀请码',
    'invitation.subtitle': '邀请码由您的客户经理提供',
    'invitation.error': '邀请码错误，请联系您的客户经理',

    'guide.tip': '您暂无华兴财富账户',
    'guide.cardtitle': '个人信息收集',
    'guide.carddesc': '请准备好身份证、护照等证件',

    'ProgressHeader.percent': '资料填写进度',

    'CustomInput.placeholder': '请输入',

    'CustomRadio.desc1': '您好，当前流程不支持美国公民或美国绿卡持有人进行信息收集',
    'CustomRadio.desc2first': '请联系您的客户经理了解详细情况',
    'CustomRadio.desc2second': ' 了解详细情况',
    'CustomRadio.cancel': '我知道了',
    'CustomRadio.confirm': '联系客服',

    'schedule.tip': '重新填写资料',

    'check.title': '尊敬的客户：',
    'check.tip1':
        '感谢您选择华兴资本财富。为方便与阁下建立业务关系的流程，你可以在本平台上填写相关资料及提供所需文件，我们会于收集阁下之资料后作审核及通知阁下后续建立业务事宜。请详细阅读使用此平台之[',
    'check.tip2': '条款及细则',
    'check.tip3': ']。',

    'AgreementDialog.tip1': '本人已阅读并同意上述条款及细则',
    'AgreementDialog.tip2': '(请滑到底部)',
    'AgreementDialog.tip3': '请阅读至底部',
};
export default zh_CN;
