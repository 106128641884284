import { combineReducers } from 'redux';

import config from './module/config';
import login from './module/login';
import hasToken from './module/hasToken';
import device from './module/device';

const rootReducer = combineReducers({
    config,
    login,
    hasToken,
    device,
});

export default rootReducer;
