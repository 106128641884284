import { useState, useEffect, useRef } from 'react';
import styles from './Input.module.scss';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useIntl } from 'react-intl';

export default props => {
    const { onBlur, onChange, onFocus, value: propsValue, ...rest } = props;
    const [inputValue, setInputValue] = useState(() => (propsValue ? propsValue : ''));

    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: 'enter' });

    const componentProps = { ...rest };

    const onChangeInput = e => {
        let value = e.target.value;
        /** 表单内部value不变，用css属性给予假反馈 */
        setInputValue(value);
        /** 只改变外层form提交时的fields value */
        onChange(value);
    };

    const onInputBlur = () => {
        onBlur();
    };

    return (
        <Input
            style={{
                background: '#F6F6F7',
                borderRadius: '0.266666667rem',
                minHeight: '1.28rem',
                color: '#1E1F20',
                margin: '0.32rem 0 0',
                fontSize: '0.426666667rem',
            }}
            placeholder={placeholder}
            onChange={onChangeInput}
            value={inputValue}
            onBlur={onInputBlur}
            {...componentProps}
        />
    );
};
