import SingleSelector from './Components/SingleSelector/SingleSelector';
import MultipleSelector from './Components/MultipleSelector/MultipleSelector';

export default props => {
    return props.selectorType !== 'multiple' ? (
        <SingleSelector {...props} />
    ) : (
        <MultipleSelector {...props} />
    );
};
