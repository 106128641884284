import styles from './ProgressHeader.module.scss';
import HeaderLogo from '../../statics/header-logo.png';
import Logo from '../../statics/SCN_CRWEALTH_RGB.png';
import ENLogo from '../../statics/EN_CRWEALTH_RGB.png';
import { FormattedMessage, useIntl } from 'react-intl';

export default ({ childrenNodes, donePercent = 0 }) => {
    const { locale } = useIntl();
    const getLogoSrc = () => {
        return locale === 'zh-CN' ? Logo : ENLogo;
    };
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={getLogoSrc()} className={styles.logo} />
                {/* <div className={styles.title}>华兴财富开户</div> */}
                <div className={styles.percent}>
                    <FormattedMessage id="ProgressHeader.percent"></FormattedMessage>：{donePercent}
                    %
                </div>
            </div>
            <div className={styles.progress}>
                <div className={styles.done} style={{ flex: donePercent }}></div>
                <div className={styles.remaining} style={{ flex: 100 - donePercent }}></div>
            </div>

            <div className={styles.wrapper}>{childrenNodes}</div>
        </div>
    );
};
