import { useState, useEffect, useRef } from 'react';
import styles from './AppendixDialog.module.scss';
import Dialog from '../../components/Dialog/Dialog';
import LinkIcon from '../../statics/invoice-accounting.png';
import openact from '../../services/openact';
import { showToast } from '../../utils/toast';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';

export default props => {
    const lang = useSelector(state => state.config.lang);
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const onClickOverlay = () => {
        __closeDialog();
    };

    const __closeDialog = () => {
        setIsShowDialog(false);
    };

    useEffect(() => {
        const getAgreement = id => {
            return openact
                .getAgreement({ id: id })
                .then(res => {
                    /** 正则去掉style标签，否则动画切换会影响其它页面样式 repaint */
                    const reg = /<(style)(?:[^>]*)?>([\s\S]*?)(?:<\/\1>[^\"\']|<\/\1>$)/gi;
                    const contentAfterFilteringStyleMarkup = res.data.content
                        .replace(reg, '')
                        .replace('<h2>', '<h4>')
                        .replace('</h2>', '</h4>');
                    setContent(contentAfterFilteringStyleMarkup);
                    setTitle(res.data.title);
                })
                .catch(err => {
                    showToast(err.msg);
                });
        };

        getAgreement(props.agreementId);
    }, [lang]);

    return (
        <div>
            <div className={styles.link} onClick={() => setIsShowDialog(true)}>
                <img className={styles.linkIcon} src={LinkIcon} />
                <a className={styles['link-a']}>{props.title}</a>
            </div>

            <Drawer
                placement="bottom"
                closable={true}
                onClose={onClickOverlay}
                visible={isShowDialog}
                height={'80%'}
                bodyStyle={{
                    padding: 0,
                }}
                className={'bottom-drawer-has-radius'}
            >
                <div className={styles.title}>{title}</div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }}></div>
            </Drawer>
        </div>
    );
};
