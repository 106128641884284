import { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import styles from './guide.module.scss';
import { withRouter } from 'react-router-dom';
import openact from '../../services/openact';
import { hideLoading, showLoading, showToast } from '../../utils/toast';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import guide_avatar from '../../statics/guide_avatar.png';
import guide_openact from '../../statics/guide_openact.png';
import guide_warning from '../../statics/guide_warning.png';
import guide_arrow from '../../statics/guide_arrow.png';
import { RightOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import SwitchLangue from '../../components/SwitchLangue/SwitchLangue';

export default withRouter(({ match, history }) => {
    const tel = match.params.tel;
    const countryCode = match.params.countryCode;
    const [isReady, setIsReady] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [data, setData] = useState({
        gender: 1,
        name: '',
    });

    const onClickOpenact = () => {
        history.push('/schedule');
    };

    useEffect(() => {
        openact
            .getStatus()
            .then(res => {
                /** 已完成流程 */
                if (res.data.is_completed === 1) {
                    // TODO 请求接口
                    setIsCompleted(true);
                    setData({
                        gender: res.data.gender,
                        name: res.data.name,
                    });
                } else {
                    setIsCompleted(false);
                }
            })
            .finally(() => setIsReady(true));
    }, []);

    const NewComer = () => {
        return (
            <div className={styles.wrapper}>
                <div className={styles['avatar-wrapper']}>
                    <img src={guide_avatar} className={styles.avatar} />
                </div>
                <div className={styles.tel}>{`${countryCode} ${tel}`}</div>

                <div className={styles['tips-wrapper']}>
                    <img src={guide_warning} className={styles.warning} />
                    <div className={styles.tips}>
                        <FormattedMessage id="guide.tip"></FormattedMessage>
                    </div>
                </div>
                <div className={styles.options}>
                    <div className={styles.openact} onClick={onClickOpenact}>
                        <img src={guide_openact} className={styles.icon} />
                        <div className={styles.content}>
                            <div className={styles.title}>
                                <FormattedMessage id="guide.cardtitle"></FormattedMessage>
                            </div>
                            <div className={styles.desc}>
                                <FormattedMessage id="guide.carddesc"></FormattedMessage>
                            </div>
                        </div>
                        <div className={styles['right-icon']}>
                            <RightOutlined
                                style={{
                                    fontSize: '0.36rem',
                                    fontWeight: 600,
                                    marginRight: '0.266666667rem',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Completed = () => {
        return (
            <div className={styles['completed-wrapper']}>
                <div className={styles['avatar-wrapper']}>
                    <img src={guide_avatar} className={styles.avatar} />
                </div>
                <div className={styles.welcome}>{`您好 ${data.name} ${
                    data.gender === 1 ? '先生' : '女士'
                }`}</div>
                <div className={styles.remind}>您已成功开通华兴财富账号！</div>
            </div>
        );
    };
    return (
        <>
            {isReady && (
                <BasicWrapper>
                    <SwitchLangue />
                    {isCompleted ? <Completed /> : <NewComer />}
                    <></>
                </BasicWrapper>
            )}
        </>
    );
});
