/**
 * 初始化全局变量，供JSON schema使用
 */
class InitWindowVariables {
    zhExcessList = [{ label: '--无--', value: '', pinyin: 'wu' }];
    twExcessList = [{ label: '--無--', value: '', pinyin: 'wu' }];
    enExcessList = [{ label: '--NULL--', value: '' }];
    init(lang) {
        this.initCountries(lang);
    }

    initCountries(lang) {
        if (lang) {
            window.requiredCountries =
                lang.toLowerCase().indexOf('cn') !== -1
                    ? this.getCountriesCodeZh()
                    : lang.toLowerCase().indexOf('zh') !== -1
                    ? this.getCountriesCodeTw()
                    : this.getCountriesCodeEn();
            window.countries =
                lang.toLowerCase().indexOf('cn') !== -1
                    ? this.zhExcessList.concat(this.getCountriesCodeZh())
                    : lang.toLowerCase().indexOf('zh') !== -1
                    ? this.twExcessList.concat(this.getCountriesCodeTw())
                    : this.enExcessList.concat(this.getCountriesCodeEn());
        } else {
            window.requiredCountries =
                navigator.languages[0].toLowerCase().indexOf('cn') !== -1
                    ? this.getCountriesCodeZh()
                    : navigator.languages[0].toLowerCase().indexOf('zh') !== -1
                    ? this.getCountriesCodeTw()
                    : this.getCountriesCodeEn();
            window.countries =
                navigator.languages[0].toLowerCase().indexOf('cn') !== -1
                    ? this.zhExcessList.concat(this.getCountriesCodeZh())
                    : navigator.languages[0].toLowerCase().indexOf('zh') !== -1
                    ? this.twExcessList.concat(this.getCountriesCodeTw())
                    : this.enExcessList.concat(this.getCountriesCodeEn());
        }
    }

    getCountriesCodeZh() {
        return [
            { label: '香港', value: 'HKG', pinyin: 'xiang gang' },
            { label: '中国', value: 'CHN', pinyin: 'zhong guo' },
            { label: '阿富汗', value: 'AFG', pinyin: 'a fu han' },
            { label: '奥兰群岛', value: 'ALA', pinyin: 'ao lan qun dao' },
            { label: '阿尔巴尼亚', value: 'ALB', pinyin: 'a er ba ni ya' },
            { label: '阿尔及利亚', value: 'DZA', pinyin: 'a er ji li ya' },
            { label: '美属萨摩亚', value: 'ASM', pinyin: 'mei shu sa mo ya' },
            { label: '安道尔', value: 'AND', pinyin: 'an dao er' },
            { label: '安哥拉', value: 'AGO', pinyin: 'an ge la' },
            { label: '安圭拉', value: 'AIA', pinyin: 'an gui la' },
            { label: '南极洲', value: 'ATA', pinyin: 'nan ji zhou' },
            { label: '安提瓜和巴布达', value: 'ATG', pinyin: 'an ti gua he ba bu da' },
            { label: '阿根廷', value: 'ARG', pinyin: 'a gen ting' },
            { label: '亚美尼亚', value: 'ARM', pinyin: 'ye mei ni ya' },
            { label: '阿鲁巴', value: 'ABW', pinyin: 'a lu ba' },
            { label: '澳大利亚', value: 'AUS', pinyin: 'ao da li ya' },
            { label: '奥地利', value: 'AUT', pinyin: 'ao di li' },
            { label: '阿塞拜疆', value: 'AZE', pinyin: 'a sai bai jiang' },
            { label: '巴哈马', value: 'BHS', pinyin: 'ba ha ma' },
            { label: '巴林', value: 'BHR', pinyin: 'ba lin' },
            { label: '孟加拉国', value: 'BGD', pinyin: 'meng jia la guo' },
            { label: '巴巴多斯', value: 'BRB', pinyin: 'ba ba duo si' },
            { label: '白俄罗斯', value: 'BLR', pinyin: 'bai e luo si' },
            { label: '比利时', value: 'BEL', pinyin: 'bi li shi' },
            { label: '伯利兹', value: 'BLZ', pinyin: 'bo li zi' },
            { label: '贝宁', value: 'BEN', pinyin: 'bei ning' },
            { label: '百慕大', value: 'BMU', pinyin: 'bai mu da' },
            { label: '不丹', value: 'BTN', pinyin: 'bu dan' },
            { label: '玻利维亚', value: 'BOL', pinyin: 'bo li wei ya' },
            { label: '波黑', value: 'BIH', pinyin: 'bo hei' },
            { label: '博茨瓦纳', value: 'BWA', pinyin: 'bi ci wa dao' },
            { label: '布维岛', value: 'BVT', pinyin: 'bu wei dao' },
            { label: '巴西', value: 'BRA', pinyin: 'ba xi' },
            { label: '英属印度洋领地', value: 'IOT', pinyin: 'ying shu yin du yang ling di' },
            { label: '文莱', value: 'BRN', pinyin: 'wen lai' },
            { label: '保加利亚', value: 'BGR', pinyin: 'bao jia li ya' },
            { label: '布基纳法索', value: 'BFA', pinyin: 'bu ji na fa suo' },
            { label: '布隆迪', value: 'BDI', pinyin: 'bu long di' },
            { label: '柬埔寨', value: 'KHM', pinyin: 'jian pu zhai' },
            { label: '喀麦隆', value: 'CMR', pinyin: 'ka mai long' },
            { label: '加拿大', value: 'CAN', pinyin: 'jia na da' },
            { label: '佛得角', value: 'CPV', pinyin: 'fu de jiao' },
            { label: '开曼群岛', value: 'CYM', pinyin: 'kai man qun dao' },
            { label: '中非', value: 'CAF', pinyin: 'zhong fei' },
            { label: '乍得', value: 'TCD', pinyin: 'zha de' },
            { label: '智利', value: 'CHL', pinyin: 'zhi li' },
            { label: '圣诞岛', value: 'CXR', pinyin: 'sheng dan dao' },
            { label: '科科斯（基林）群岛', value: 'CCK', pinyin: 'ke ke si qun dao' },
            { label: '哥伦比亚', value: 'COL', pinyin: 'ge lun bi ya' },
            { label: '科摩罗', value: 'COM', pinyin: 'ke mo luo' },
            { label: '刚果（布）', value: 'COG', pinyin: 'gang guo bu' },
            { label: '刚果（金）', value: 'COD', pinyin: 'gang guo jin' },
            { label: '库克群岛', value: 'COK', pinyin: 'ku ke qun dao' },
            { label: '哥斯达黎加', value: 'CRI', pinyin: 'ge si da li jia' },
            { label: '科特迪瓦', value: 'CIV', pinyin: 'ke te di wa' },
            { label: '克罗地亚', value: 'HRV', pinyin: 'ke luo di ya' },
            { label: '古巴', value: 'CUB', pinyin: 'gu ba' },
            { label: '塞浦路斯', value: 'CYP', pinyin: 'sai pu luo si' },
            { label: '捷克', value: 'CZE', pinyin: 'jie ke' },
            { label: '丹麦', value: 'DNK', pinyin: 'dan mai' },
            { label: '吉布提', value: 'DJI', pinyin: 'ji bu ti' },
            { label: '多米尼克', value: 'DMA', pinyin: 'duo mi ni ke' },
            { label: '多米尼加', value: 'DOM', pinyin: 'duo mi ni jia' },
            { label: '厄瓜多尔', value: 'ECU', pinyin: 'e gua duo er' },
            { label: '埃及', value: 'EGY', pinyin: 'ai ji' },
            { label: '萨尔瓦多', value: 'SLV', pinyin: 'sa er wa duo' },
            { label: '赤道几内亚', value: 'GNQ', pinyin: 'chi dao ji nei ya' },
            { label: '厄立特里亚', value: 'ERI', pinyin: 'e li te li ya' },
            { label: '爱沙尼亚', value: 'EST', pinyin: 'ai sha ni ya' },
            { label: '埃塞俄比亚', value: 'ETH', pinyin: 'ai sai e bi ya' },
            { label: '福克兰群岛（马尔维纳斯）', value: 'FLK', pinyin: 'fu ke lan qun dao' },
            { label: '法罗群岛', value: 'FRO', pinyin: 'fa luo qun dao' },
            { label: '斐济', value: 'FJI', pinyin: 'fei ji' },
            { label: '芬兰', value: 'FIN', pinyin: 'fen lan' },
            { label: '法国', value: 'FRA', pinyin: 'fa guo' },
            { label: '法属圭亚那', value: 'GUF', pinyin: 'fa shu gui ya na' },
            { label: '法属波利尼西亚', value: 'PYF', pinyin: 'fa shu bo li ni xi ya' },
            { label: '法属南部领地', value: 'ATF', pinyin: 'fa shu nan bu ling di' },
            { label: '加蓬', value: 'GAB', pinyin: 'jia peng' },
            { label: '冈比亚', value: 'GMB', pinyin: 'gang bi ya' },
            { label: '格鲁吉亚', value: 'GEO', pinyin: 'ge lu ji ya' },
            { label: '德国', value: 'DEU', pinyin: 'de guo' },
            { label: '加纳', value: 'GHA', pinyin: 'ga na' },
            { label: '直布罗陀', value: 'GIB', pinyin: 'zhi bu luo tuo' },
            { label: '希腊', value: 'GRC', pinyin: 'xi la' },
            { label: '格陵兰', value: 'GRL', pinyin: 'ge ling lan' },
            { label: '格林纳达', value: 'GRD', pinyin: 'ge lin na da' },
            { label: '瓜德罗普', value: 'GLP', pinyin: 'gua de luo pu' },
            { label: '关岛', value: 'GUM', pinyin: 'guan dao' },
            { label: '危地马拉', value: 'GTM', pinyin: 'wei di ma la' },
            { label: '格恩西岛', value: 'GGY', pinyin: 'ge en xi dao' },
            { label: '几内亚', value: 'GIN', pinyin: 'jie nei ya' },
            { label: '几内亚比绍', value: 'GNB', pinyin: 'ji nei ya bi shao' },
            { label: '圭亚那', value: 'GUY', pinyin: 'gui ya na' },
            { label: '海地', value: 'HTI', pinyin: 'hai di' },
            {
                label: '赫德岛和麦克唐纳岛',
                value: 'HMD',
                pinyin: 'he de dao he mai ke tang na dao',
            },
            { label: '梵蒂冈', value: 'VAT', pinyin: 'fan di gang' },
            { label: '洪都拉斯', value: 'HND', pinyin: 'hong du la si' },
            { label: '匈牙利', value: 'HUN', pinyin: 'xiong ya' },
            { label: '冰岛', value: 'ISL', pinyin: 'bing dao' },
            { label: '印度', value: 'IND', pinyin: 'yin du' },
            { label: '印度尼西亚', value: 'IDN', pinyin: 'yin du ni xi ya' },
            { label: '伊朗', value: 'IRN', pinyin: 'yi lang' },
            { label: '伊拉克', value: 'IRQ', pinyin: 'yi la ke' },
            { label: '爱尔兰', value: 'IRL', pinyin: 'ai er lan' },
            { label: '英国属地曼岛', value: 'IMN', pinyin: 'yang guo shu di man dao' },
            { label: '以色列', value: 'ISR', pinyin: 'yi se lie' },
            { label: '意大利', value: 'ITA', pinyin: 'yi da li' },
            { label: '牙买加', value: 'JAM', pinyin: 'ya mai jia' },
            { label: '日本', value: 'JPN', pinyin: 'ri ben' },
            { label: '泽西岛', value: 'JEY', pinyin: 'ze xi dao' },
            { label: '约旦', value: 'JOR', pinyin: 'yue dan' },
            { label: '哈萨克斯坦', value: 'KAZ', pinyin: 'ha sa ke si tan' },
            { label: '肯尼亚', value: 'KEN', pinyin: 'ken ni ya' },
            { label: '基里巴斯', value: 'KIR', pinyin: 'ji li ba si' },
            { label: '朝鲜', value: 'PRK', pinyin: 'chao xian' },
            { label: '韩国', value: 'KOR', pinyin: 'han guo' },
            { label: '科威特', value: 'KWT', pinyin: 'ke wei te' },
            { label: '吉尔吉斯斯坦', value: 'KGZ', pinyin: 'ji er ji si si tan' },
            { label: '老挝', value: 'LAO', pinyin: 'lao wo' },
            { label: '拉脱维亚', value: 'LVA', pinyin: 'la tuo wei ya' },
            { label: '黎巴嫩', value: 'LBN', pinyin: 'li ba nen' },
            { label: '莱索托', value: 'LSO', pinyin: 'suo lai tuo' },
            { label: '利比里亚', value: 'LBR', pinyin: 'li bi li ya' },
            { label: '利比亚', value: 'LBY', pinyin: 'li bi ya' },
            { label: '列支敦士登', value: 'LIE', pinyin: 'lie zhi dun shi deng' },
            { label: '立陶宛', value: 'LTU', pinyin: 'li tao wan' },
            { label: '卢森堡', value: 'LUX', pinyin: 'lun sen bao' },
            { label: '澳门', value: 'MAC', pinyin: 'ao men' },
            { label: '前南马其顿', value: 'MKD', pinyin: 'qian nan ma qi dun' },
            { label: '马达加斯加', value: 'MDG', pinyin: 'ma da jia si jia' },
            { label: '马拉维', value: 'MWI', pinyin: 'ma la wei' },
            { label: '马来西亚', value: 'MYS', pinyin: 'ma lai xi ya' },
            { label: '马尔代夫', value: 'MDV', pinyin: 'ma er dai fu' },
            { label: '马里', value: 'MLI', pinyin: 'ma li' },
            { label: '马耳他', value: 'MLT', pinyin: 'ma er ta' },
            { label: '马绍尔群岛', value: 'MHL', pinyin: 'ma shao er qun dao' },
            { label: '马提尼克', value: 'MTQ', pinyin: 'ma ti ni ke' },
            { label: '毛利塔尼亚', value: 'MRT', pinyin: 'mao li qiu si ta ni ya' },
            { label: '毛里求斯', value: 'MUS', pinyin: 'mao li qiu si' },
            { label: '马约特', value: 'MYT', pinyin: 'ma yue te' },
            { label: '墨西哥', value: 'MEX', pinyin: 'mo xi ge' },
            { label: '密克罗尼西亚联邦', value: 'FSM', pinyin: 'mi ke luo ni xi ya lian meng' },
            { label: '摩尔多瓦', value: 'MDA', pinyin: 'mo er duo wa' },
            { label: '摩纳哥', value: 'MCO', pinyin: 'mo na ge' },
            { label: '蒙古', value: 'MNG', pinyin: 'meng gu' },
            { label: '黑山', value: 'MNE', pinyin: 'hei shan' },
            { label: '蒙特塞拉特', value: 'MSR', pinyin: 'meng te sai la te' },
            { label: '摩洛哥', value: 'MAR', pinyin: 'mo luo ge' },
            { label: '莫桑比克', value: 'MOZ', pinyin: 'mo sang bi ke' },
            { label: '缅甸', value: 'MMR', pinyin: 'mian dian' },
            { label: '纳米比亚', value: 'NAM', pinyin: 'na mi bi ya' },
            { label: '瑙鲁', value: 'NRU', pinyin: 'nao lu' },
            { label: '尼泊尔', value: 'NPL', pinyin: 'ni bo er' },
            { label: '荷兰', value: 'NLD', pinyin: 'he lan' },
            { label: '荷属安的列斯', value: 'ANT', pinyin: 'he shu an di lie si' },
            { label: '新喀里多尼亚', value: 'NCL', pinyin: 'xin ke li duo ni ya' },
            { label: '新西兰', value: 'NZL', pinyin: 'xin xi lan' },
            { label: '尼加拉瓜', value: 'NIC', pinyin: 'ni jia la gua' },
            { label: '尼日尔', value: 'NER', pinyin: 'ni ri er' },
            { label: '尼日利亚', value: 'NGA', pinyin: 'ni ri li ya' },
            { label: '纽埃', value: 'NIU', pinyin: 'niu ai' },
            { label: '诺福克岛', value: 'NFK', pinyin: 'nuo fu ke dao' },
            { label: '北马里亚纳', value: 'MNP', pinyin: 'bei ma li ya na' },
            { label: '挪威', value: 'NOR', pinyin: 'nuo wei' },
            { label: '阿曼', value: 'OMN', pinyin: 'a man' },
            { label: '巴基斯坦', value: 'PAK', pinyin: 'ba ji si tan' },
            { label: '帕劳', value: 'PLW', pinyin: 'pa lao' },
            { label: '巴勒斯坦', value: 'PSE', pinyin: 'ba le si tan' },
            { label: '巴拿马', value: 'PAN', pinyin: 'ba na ma' },
            { label: '巴布亚新几内亚', value: 'PNG', pinyin: 'ba bu ya xin ji nei ya' },
            { label: '巴拉圭', value: 'PRY', pinyin: 'ba la gui' },
            { label: '秘鲁', value: 'PER', pinyin: 'bi lu' },
            { label: '菲律宾', value: 'PHL', pinyin: 'fei lv bin' },
            { label: '皮特凯恩', value: 'PCN', pinyin: 'pi te kai en' },
            { label: '波兰', value: 'POL', pinyin: 'bo lan' },
            { label: '葡萄牙', value: 'PRT', pinyin: 'pu tao ya' },
            { label: '波多黎各', value: 'PRI', pinyin: 'bo duo li ge' },
            { label: '卡塔尔', value: 'QAT', pinyin: 'ka ta er' },
            { label: '留尼汪', value: 'REU', pinyin: 'liu ni wang' },
            { label: '罗马尼亚', value: 'ROU', pinyin: 'luo ma ni ya' },
            { label: '俄罗斯联邦', value: 'RUS', pinyin: 'e luo si lian bang' },
            { label: '卢旺达', value: 'RWA', pinyin: 'lu wang da' },
            { label: '圣赫勒拿', value: 'SHN', pinyin: 'sheng he le na' },
            { label: '圣基茨和尼维斯', value: 'KNA', pinyin: 'sheng ji ci he ni wei si' },
            { label: '圣卢西亚', value: 'LCA', pinyin: 'sheng lu xi ya' },
            { label: '圣皮埃尔和密克隆', value: 'SPM', pinyin: 'sheng pi ei er he mi ke long' },
            {
                label: '圣文森特和格林纳丁斯',
                value: 'VCT',
                pinyin: 'sheng wen sen te he ge lin na ding si',
            },
            { label: '萨摩亚', value: 'WSM', pinyin: 'sa mo ya' },
            { label: '圣马力诺', value: 'SMR', pinyin: 'sheng ma li nuo' },
            { label: '圣多美和普林西比', value: 'STP', pinyin: 'sheng duo mei he pu lin xi bi' },
            { label: '沙特阿拉伯', value: 'SAU', pinyin: 'sha te a la bo' },
            { label: '塞内加尔', value: 'SEN', pinyin: 'sai nei jia er' },
            { label: '塞尔维亚', value: 'SRB', pinyin: 'sai er wei ya' },
            { label: '塞舌尔', value: 'SYC', pinyin: 'sai she er' },
            { label: '塞拉利昂', value: 'SLE', pinyin: 'sai la li ang' },
            { label: '新加坡', value: 'SGP', pinyin: 'xin jia po' },
            { label: '斯洛伐克', value: 'SVK', pinyin: 'si luo fa ke' },
            { label: '斯洛文尼亚', value: 'SVN', pinyin: 'si luo wen ni ya' },
            { label: '所罗门群岛', value: 'SLB', pinyin: 'suo luo men qun dao' },
            { label: '索马里', value: 'SOM', pinyin: 'suo ma li' },
            { label: '南非', value: 'ZAF', pinyin: 'nan fei' },
            {
                label: '南乔治亚岛和南桑德韦奇岛',
                value: 'SGS',
                pinyin: 'nan qiao zhi ya dao he nan sang de wei qi dao',
            },
            { label: '西班牙', value: 'ESP', pinyin: 'xi ban ya' },
            { label: '斯里兰卡', value: 'LKA', pinyin: 'si li lan ka' },
            { label: '苏丹', value: 'SDN', pinyin: 'su dan' },
            { label: '苏里南', value: 'SUR', pinyin: 'su li nan' },
            {
                label: '斯瓦尔巴岛和扬马延岛',
                value: 'SJM',
                pinyin: 'si wa er ba dao he yang ma yan dao',
            },
            { label: '斯威士兰', value: 'SWZ', pinyin: 'si wei shi lan' },
            { label: '瑞典', value: 'SWE', pinyin: 'rui dian' },
            { label: '瑞士', value: 'CHE', pinyin: 'rui shi' },
            { label: '叙利亚', value: 'SYR', pinyin: 'xv li ya' },
            { label: '台湾', value: 'TWN', pinyin: 'tai wan' },
            { label: '塔吉克斯坦', value: 'TJK', pinyin: 'ta ji ke si tan' },
            { label: '坦桑尼亚', value: 'TZA', pinyin: 'tan sang ni ya' },
            { label: '泰国', value: 'THA', pinyin: 'tai guo' },
            { label: '东帝汶', value: 'TLS', pinyin: 'dong di wen' },
            { label: '多哥', value: 'TGO', pinyin: 'duo ge' },
            { label: '托克劳', value: 'TKL', pinyin: 'tuo ke lao' },
            { label: '汤加', value: 'TON', pinyin: 'tang jia' },
            { label: '特立尼达和多巴哥', value: 'TTO', pinyin: 'te li ni da he duo ba ge' },
            { label: '突尼斯', value: 'TUN', pinyin: 'tu ni si' },
            { label: '土耳其', value: 'TUR', pinyin: 'tu er qi' },
            { label: '土库曼斯坦', value: 'TKM', pinyin: 'tu ku man si tan' },
            { label: '特克斯和凯科斯群岛', value: 'TCA', pinyin: 'te ke si he kai ke si qun dao' },
            { label: '图瓦卢', value: 'TUV', pinyin: 'tu wa lu' },
            { label: '乌干达', value: 'UGA', pinyin: 'wu gan da' },
            { label: '乌克兰', value: 'UKR', pinyin: 'wu ke lan' },
            { label: '阿联酋', value: 'ARE', pinyin: 'a lian qiu' },
            { label: '英国', value: 'GBR', pinyin: 'ying guo' },
            { label: '美国', value: 'USA', pinyin: 'mei guo' },
            { label: '美国本土外小岛屿', value: 'UMI', pinyin: 'mei guo ben tu wai xiao dao yu' },
            { label: '乌拉圭', value: 'URY', pinyin: 'wu la gui' },
            { label: '乌兹别克斯坦', value: 'UZB', pinyin: 'wu zi bie ke si tan' },
            { label: '瓦努阿图', value: 'VUT', pinyin: 'wa nu a tu' },
            { label: '委内瑞拉', value: 'VEN', pinyin: 'wei nei rui la' },
            { label: '越南', value: 'VNM', pinyin: 'yue nan' },
            { label: '英属维尔京群岛', value: 'VGB', pinyin: 'ying shu wei er jing qun dao' },
            { label: '美属维尔京群岛', value: 'VIR', pinyin: 'mei shu wei er jing qun dao' },
            { label: '瓦利斯和富图纳', value: 'WLF', pinyin: 'wa li si he fu tu na' },
            { label: '西撒哈拉', value: 'ESH', pinyin: 'xi sa ha la' },
            { label: '也门', value: 'YEM', pinyin: 'ye men' },
            { label: '赞比亚', value: 'ZMB', pinyin: 'zan bi ya' },
            { label: '津巴布韦', value: 'ZWE', pinyin: 'jing ba bu wei' },
        ];
    }

    getCountriesCodeTw() {
        return [
            { label: '香港', value: 'HKG', pinyin: 'xiang gang' },
            { label: '中國', value: 'CHN', pinyin: 'zhong guo' },
            { label: '阿富汗', value: 'AFG', pinyin: 'a fu han' },
            { label: '奧蘭群島', value: 'ALA', pinyin: 'ao lan qun dao' },
            { label: '阿爾巴尼亞', value: 'ALB', pinyin: 'a er ba ni ya' },
            { label: '阿爾及利亞', value: 'DZA', pinyin: 'a er ji li ya' },
            { label: '美屬薩摩亞', value: 'ASM', pinyin: 'mei shu sa mo ya' },
            { label: '安道爾', value: 'AND', pinyin: 'an dao er' },
            { label: '安哥拉', value: 'AGO', pinyin: 'an ge la' },
            { label: '安圭拉', value: 'AIA', pinyin: 'an gui la' },
            { label: '南極洲', value: 'ATA', pinyin: 'nan ji zhou' },
            { label: '安提瓜和巴布達', value: 'ATG', pinyin: 'an ti gua he ba bu da' },
            { label: '阿根廷', value: 'ARG', pinyin: 'a gen ting' },
            { label: '亞美尼亞', value: 'ARM', pinyin: 'ye mei ni ya' },
            { label: '阿魯巴', value: 'ABW', pinyin: 'a lu ba' },
            { label: '澳大利亞', value: 'AUS', pinyin: 'ao da li ya' },
            { label: '奧地利', value: 'AUT', pinyin: 'ao di li' },
            { label: '阿塞拜疆', value: 'AZE', pinyin: 'a sai bai jiang' },
            { label: '巴哈馬', value: 'BHS', pinyin: 'ba ha ma' },
            { label: '巴林', value: 'BHR', pinyin: 'ba lin' },
            { label: '孟加拉國', value: 'BGD', pinyin: 'meng jia la guo' },
            { label: '巴巴多斯', value: 'BRB', pinyin: 'ba ba duo si' },
            { label: '白俄羅斯', value: 'BLR', pinyin: 'bai e luo si' },
            { label: '比利時', value: 'BEL', pinyin: 'bi li shi' },
            { label: '伯利茲', value: 'BLZ', pinyin: 'bo li zi' },
            { label: '貝寧', value: 'BEN', pinyin: 'bei ning' },
            { label: '百慕大', value: 'BMU', pinyin: 'bai mu da' },
            { label: '不丹', value: 'BTN', pinyin: 'bu dan' },
            { label: '玻利維亞', value: 'BOL', pinyin: 'bo li wei ya' },
            { label: '波黑', value: 'BIH', pinyin: 'bo hei' },
            { label: '博茨瓦納', value: 'BWA', pinyin: 'bi ci wa dao' },
            { label: '布維島', value: 'BVT', pinyin: 'bu wei dao' },
            { label: '巴西', value: 'BRA', pinyin: 'ba xi' },
            { label: '英屬印度洋領地', value: 'IOT', pinyin: 'ying shu yin du yang ling di' },
            { label: '文萊', value: 'BRN', pinyin: 'wen lai' },
            { label: '保加利亞', value: 'BGR', pinyin: 'bao jia li ya' },
            { label: '布基納法索', value: 'BFA', pinyin: 'bu ji na fa suo' },
            { label: '布隆迪', value: 'BDI', pinyin: 'bu long di' },
            { label: '柬埔寨', value: 'KHM', pinyin: 'jian pu zhai' },
            { label: '喀麥隆', value: 'CMR', pinyin: 'ka mai long' },
            { label: '加拿大', value: 'CAN', pinyin: 'jia na da' },
            { label: '佛得角', value: 'CPV', pinyin: 'fu de jiao' },
            { label: '開曼群島', value: 'CYM', pinyin: 'kai man qun dao' },
            { label: '中非', value: 'CAF', pinyin: 'zhong fei' },
            { label: '乍得', value: 'TCD', pinyin: 'zha de' },
            { label: '智利', value: 'CHL', pinyin: 'zhi li' },
            { label: '聖誕島', value: 'CXR', pinyin: 'sheng dan dao' },
            { label: '科科斯（基林）群島', value: 'CCK', pinyin: 'ke ke si qun dao' },
            { label: '哥倫比亞', value: 'COL', pinyin: 'ge lun bi ya' },
            { label: '科摩羅', value: 'COM', pinyin: 'ke mo luo' },
            { label: '剛果（布）', value: 'COG', pinyin: 'gang guo bu' },
            { label: '剛果（金）', value: 'COD', pinyin: 'gang guo jin' },
            { label: '庫克群島', value: 'COK', pinyin: 'ku ke qun dao' },
            { label: '哥斯達黎加', value: 'CRI', pinyin: 'ge si da li jia' },
            { label: '科特迪瓦', value: 'CIV', pinyin: 'ke te di wa' },
            { label: '克羅地亞', value: 'HRV', pinyin: 'ke luo di ya' },
            { label: '古巴', value: 'CUB', pinyin: 'gu ba' },
            { label: '塞浦路斯', value: 'CYP', pinyin: 'sai pu luo si' },
            { label: '捷克', value: 'CZE', pinyin: 'jie ke' },
            { label: '丹麥', value: 'DNK', pinyin: 'dan mai' },
            { label: '吉布提', value: 'DJI', pinyin: 'ji bu ti' },
            { label: '多米尼克', value: 'DMA', pinyin: 'duo mi ni ke' },
            { label: '多米尼加', value: 'DOM', pinyin: 'duo mi ni jia' },
            { label: '厄瓜多爾', value: 'ECU', pinyin: 'e gua duo er' },
            { label: '埃及', value: 'EGY', pinyin: 'ai ji' },
            { label: '薩爾瓦多', value: 'SLV', pinyin: 'sa er wa duo' },
            { label: '赤道幾內亞', value: 'GNQ', pinyin: 'chi dao ji nei ya' },
            { label: '厄立特裏亞', value: 'ERI', pinyin: 'e li te li ya' },
            { label: '愛沙尼亞', value: 'EST', pinyin: 'ai sha ni ya' },
            { label: '埃塞俄比亞', value: 'ETH', pinyin: 'ai sai e bi ya' },
            { label: '福克蘭群島（馬爾維納斯）', value: 'FLK', pinyin: 'fu ke lan qun dao' },
            { label: '法羅群島', value: 'FRO', pinyin: 'fa luo qun dao' },
            { label: '斐濟', value: 'FJI', pinyin: 'fei ji' },
            { label: '芬蘭', value: 'FIN', pinyin: 'fen lan' },
            { label: '法國', value: 'FRA', pinyin: 'fa guo' },
            { label: '法屬圭亞那', value: 'GUF', pinyin: 'fa shu gui ya na' },
            { label: '法屬波利尼西亞', value: 'PYF', pinyin: 'fa shu bo li ni xi ya' },
            { label: '法屬南部領地', value: 'ATF', pinyin: 'fa shu nan bu ling di' },
            { label: '加蓬', value: 'GAB', pinyin: 'jia peng' },
            { label: '岡比亞', value: 'GMB', pinyin: 'gang bi ya' },
            { label: '格魯吉亞', value: 'GEO', pinyin: 'ge lu ji ya' },
            { label: '德國', value: 'DEU', pinyin: 'de guo' },
            { label: '加納', value: 'GHA', pinyin: 'ga na' },
            { label: '直布羅陀', value: 'GIB', pinyin: 'zhi bu luo tuo' },
            { label: '希臘', value: 'GRC', pinyin: 'xi la' },
            { label: '格陵蘭', value: 'GRL', pinyin: 'ge ling lan' },
            { label: '格林納達', value: 'GRD', pinyin: 'ge lin na da' },
            { label: '瓜德羅普', value: 'GLP', pinyin: 'gua de luo pu' },
            { label: '關島', value: 'GUM', pinyin: 'guan dao' },
            { label: '危地馬拉', value: 'GTM', pinyin: 'wei di ma la' },
            { label: '格恩西島', value: 'GGY', pinyin: 'ge en xi dao' },
            { label: '幾內亞', value: 'GIN', pinyin: 'jie nei ya' },
            { label: '幾內亞比紹', value: 'GNB', pinyin: 'ji nei ya bi shao' },
            { label: '圭亞那', value: 'GUY', pinyin: 'gui ya na' },
            { label: '海地', value: 'HTI', pinyin: 'hai di' },
            {
                label: '赫德島和麥克唐納島',
                value: 'HMD',
                pinyin: 'he de dao he mai ke tang na dao',
            },
            { label: '梵蒂岡', value: 'VAT', pinyin: 'fan di gang' },
            { label: '洪都拉斯', value: 'HND', pinyin: 'hong du la si' },
            { label: '匈牙利', value: 'HUN', pinyin: 'xiong ya' },
            { label: '冰島', value: 'ISL', pinyin: 'bing dao' },
            { label: '印度', value: 'IND', pinyin: 'yin du' },
            { label: '印度尼西亞', value: 'IDN', pinyin: 'yin du ni xi ya' },
            { label: '伊朗', value: 'IRN', pinyin: 'yi lang' },
            { label: '伊拉克', value: 'IRQ', pinyin: 'yi la ke' },
            { label: '愛爾蘭', value: 'IRL', pinyin: 'ai er lan' },
            { label: '英國屬地曼島', value: 'IMN', pinyin: 'yang guo shu di man dao' },
            { label: '以色列', value: 'ISR', pinyin: 'yi se lie' },
            { label: '意大利', value: 'ITA', pinyin: 'yi da li' },
            { label: '牙買加', value: 'JAM', pinyin: 'ya mai jia' },
            { label: '日本', value: 'JPN', pinyin: 'ri ben' },
            { label: '澤西島', value: 'JEY', pinyin: 'ze xi dao' },
            { label: '約旦', value: 'JOR', pinyin: 'yue dan' },
            { label: '哈薩克斯坦', value: 'KAZ', pinyin: 'ha sa ke si tan' },
            { label: '肯尼亞', value: 'KEN', pinyin: 'ken ni ya' },
            { label: '基裏巴斯', value: 'KIR', pinyin: 'ji li ba si' },
            { label: '朝鮮', value: 'PRK', pinyin: 'chao xian' },
            { label: '韓國', value: 'KOR', pinyin: 'han guo' },
            { label: '科威特', value: 'KWT', pinyin: 'ke wei te' },
            { label: '吉爾吉斯斯坦', value: 'KGZ', pinyin: 'ji er ji si si tan' },
            { label: '老撾', value: 'LAO', pinyin: 'lao wo' },
            { label: '拉脫維亞', value: 'LVA', pinyin: 'la tuo wei ya' },
            { label: '黎巴嫩', value: 'LBN', pinyin: 'li ba nen' },
            { label: '萊索托', value: 'LSO', pinyin: 'suo lai tuo' },
            { label: '利比裏亞', value: 'LBR', pinyin: 'li bi li ya' },
            { label: '利比亞', value: 'LBY', pinyin: 'li bi ya' },
            { label: '列支敦士登', value: 'LIE', pinyin: 'lie zhi dun shi deng' },
            { label: '立陶宛', value: 'LTU', pinyin: 'li tao wan' },
            { label: '盧森堡', value: 'LUX', pinyin: 'lun sen bao' },
            { label: '澳門', value: 'MAC', pinyin: 'ao men' },
            { label: '前南馬其頓', value: 'MKD', pinyin: 'qian nan ma qi dun' },
            { label: '馬達加斯加', value: 'MDG', pinyin: 'ma da jia si jia' },
            { label: '馬拉維', value: 'MWI', pinyin: 'ma la wei' },
            { label: '馬來西亞', value: 'MYS', pinyin: 'ma lai xi ya' },
            { label: '馬爾代夫', value: 'MDV', pinyin: 'ma er dai fu' },
            { label: '馬裏', value: 'MLI', pinyin: 'ma li' },
            { label: '馬耳他', value: 'MLT', pinyin: 'ma er ta' },
            { label: '馬紹爾群島', value: 'MHL', pinyin: 'ma shao er qun dao' },
            { label: '馬提尼克', value: 'MTQ', pinyin: 'ma ti ni ke' },
            { label: '毛利塔尼亞', value: 'MRT', pinyin: 'mao li qiu si ta ni ya' },
            { label: '毛裏求斯', value: 'MUS', pinyin: 'mao li qiu si' },
            { label: '馬約特', value: 'MYT', pinyin: 'ma yue te' },
            { label: '墨西哥', value: 'MEX', pinyin: 'mo xi ge' },
            { label: '密克羅尼西亞聯邦', value: 'FSM', pinyin: 'mi ke luo ni xi ya lian meng' },
            { label: '摩爾多瓦', value: 'MDA', pinyin: 'mo er duo wa' },
            { label: '摩納哥', value: 'MCO', pinyin: 'mo na ge' },
            { label: '蒙古', value: 'MNG', pinyin: 'meng gu' },
            { label: '黑山', value: 'MNE', pinyin: 'hei shan' },
            { label: '蒙特塞拉特', value: 'MSR', pinyin: 'meng te sai la te' },
            { label: '摩洛哥', value: 'MAR', pinyin: 'mo luo ge' },
            { label: '莫桑比克', value: 'MOZ', pinyin: 'mo sang bi ke' },
            { label: '緬甸', value: 'MMR', pinyin: 'mian dian' },
            { label: '納米比亞', value: 'NAM', pinyin: 'na mi bi ya' },
            { label: '瑙魯', value: 'NRU', pinyin: 'nao lu' },
            { label: '尼泊爾', value: 'NPL', pinyin: 'ni bo er' },
            { label: '荷蘭', value: 'NLD', pinyin: 'he lan' },
            { label: '荷屬安的列斯', value: 'ANT', pinyin: 'he shu an di lie si' },
            { label: '新喀裏多尼亞', value: 'NCL', pinyin: 'xin ke li duo ni ya' },
            { label: '新西蘭', value: 'NZL', pinyin: 'xin xi lan' },
            { label: '尼加拉瓜', value: 'NIC', pinyin: 'ni jia la gua' },
            { label: '尼日爾', value: 'NER', pinyin: 'ni ri er' },
            { label: '尼日利亞', value: 'NGA', pinyin: 'ni ri li ya' },
            { label: '紐埃', value: 'NIU', pinyin: 'niu ai' },
            { label: '諾福克島', value: 'NFK', pinyin: 'nuo fu ke dao' },
            { label: '北馬裏亞納', value: 'MNP', pinyin: 'bei ma li ya na' },
            { label: '挪威', value: 'NOR', pinyin: 'nuo wei' },
            { label: '阿曼', value: 'OMN', pinyin: 'a man' },
            { label: '巴基斯坦', value: 'PAK', pinyin: 'ba ji si tan' },
            { label: '帕勞', value: 'PLW', pinyin: 'pa lao' },
            { label: '巴勒斯坦', value: 'PSE', pinyin: 'ba le si tan' },
            { label: '巴拿馬', value: 'PAN', pinyin: 'ba na ma' },
            { label: '巴布亞新幾內亞', value: 'PNG', pinyin: 'ba bu ya xin ji nei ya' },
            { label: '巴拉圭', value: 'PRY', pinyin: 'ba la gui' },
            { label: '秘魯', value: 'PER', pinyin: 'bi lu' },
            { label: '菲律賓', value: 'PHL', pinyin: 'fei lv bin' },
            { label: '皮特凱恩', value: 'PCN', pinyin: 'pi te kai en' },
            { label: '波蘭', value: 'POL', pinyin: 'bo lan' },
            { label: '葡萄牙', value: 'PRT', pinyin: 'pu tao ya' },
            { label: '波多黎各', value: 'PRI', pinyin: 'bo duo li ge' },
            { label: '卡塔爾', value: 'QAT', pinyin: 'ka ta er' },
            { label: '留尼汪', value: 'REU', pinyin: 'liu ni wang' },
            { label: '羅馬尼亞', value: 'ROU', pinyin: 'luo ma ni ya' },
            { label: '俄羅斯聯邦', value: 'RUS', pinyin: 'e luo si lian bang' },
            { label: '盧旺達', value: 'RWA', pinyin: 'lu wang da' },
            { label: '聖赫勒拿', value: 'SHN', pinyin: 'sheng he le na' },
            { label: '聖基茨和尼維斯', value: 'KNA', pinyin: 'sheng ji ci he ni wei si' },
            { label: '聖盧西亞', value: 'LCA', pinyin: 'sheng lu xi ya' },
            { label: '聖皮埃爾和密克隆', value: 'SPM', pinyin: 'sheng pi ei er he mi ke long' },
            {
                label: '聖文森特和格林納丁斯',
                value: 'VCT',
                pinyin: 'sheng wen sen te he ge lin na ding si',
            },
            { label: '薩摩亞', value: 'WSM', pinyin: 'sa mo ya' },
            { label: '聖馬力諾', value: 'SMR', pinyin: 'sheng ma li nuo' },
            { label: '聖多美和普林西比', value: 'STP', pinyin: 'sheng duo mei he pu lin xi bi' },
            { label: '沙特阿拉伯', value: 'SAU', pinyin: 'sha te a la bo' },
            { label: '塞內加爾', value: 'SEN', pinyin: 'sai nei jia er' },
            { label: '塞爾維亞', value: 'SRB', pinyin: 'sai er wei ya' },
            { label: '塞舌爾', value: 'SYC', pinyin: 'sai she er' },
            { label: '塞拉利昂', value: 'SLE', pinyin: 'sai la li ang' },
            { label: '新加坡', value: 'SGP', pinyin: 'xin jia po' },
            { label: '斯洛伐克', value: 'SVK', pinyin: 'si luo fa ke' },
            { label: '斯洛文尼亞', value: 'SVN', pinyin: 'si luo wen ni ya' },
            { label: '所羅門群島', value: 'SLB', pinyin: 'suo luo men qun dao' },
            { label: '索馬裏', value: 'SOM', pinyin: 'suo ma li' },
            { label: '南非', value: 'ZAF', pinyin: 'nan fei' },
            {
                label: '南喬治亞島和南桑德韋奇島',
                value: 'SGS',
                pinyin: 'nan qiao zhi ya dao he nan sang de wei qi dao',
            },
            { label: '西班牙', value: 'ESP', pinyin: 'xi ban ya' },
            { label: '斯裏蘭卡', value: 'LKA', pinyin: 'si li lan ka' },
            { label: '蘇丹', value: 'SDN', pinyin: 'su dan' },
            { label: '蘇裏南', value: 'SUR', pinyin: 'su li nan' },
            {
                label: '斯瓦爾巴島和揚馬延島',
                value: 'SJM',
                pinyin: 'si wa er ba dao he yang ma yan dao',
            },
            { label: '斯威士蘭', value: 'SWZ', pinyin: 'si wei shi lan' },
            { label: '瑞典', value: 'SWE', pinyin: 'rui dian' },
            { label: '瑞士', value: 'CHE', pinyin: 'rui shi' },
            { label: '敘利亞', value: 'SYR', pinyin: 'xv li ya' },
            { label: '臺灣', value: 'TWN', pinyin: 'tai wan' },
            { label: '塔吉克斯坦', value: 'TJK', pinyin: 'ta ji ke si tan' },
            { label: '坦桑尼亞', value: 'TZA', pinyin: 'tan sang ni ya' },
            { label: '泰國', value: 'THA', pinyin: 'tai guo' },
            { label: '東帝汶', value: 'TLS', pinyin: 'dong di wen' },
            { label: '多哥', value: 'TGO', pinyin: 'duo ge' },
            { label: '托克勞', value: 'TKL', pinyin: 'tuo ke lao' },
            { label: '湯加', value: 'TON', pinyin: 'tang jia' },
            { label: '特立尼達和多巴哥', value: 'TTO', pinyin: 'te li ni da he duo ba ge' },
            { label: '突尼斯', value: 'TUN', pinyin: 'tu ni si' },
            { label: '土耳其', value: 'TUR', pinyin: 'tu er qi' },
            { label: '土庫曼斯坦', value: 'TKM', pinyin: 'tu ku man si tan' },
            { label: '特克斯和凱科斯群島', value: 'TCA', pinyin: 'te ke si he kai ke si qun dao' },
            { label: '圖瓦盧', value: 'TUV', pinyin: 'tu wa lu' },
            { label: '烏幹達', value: 'UGA', pinyin: 'wu gan da' },
            { label: '烏克蘭', value: 'UKR', pinyin: 'wu ke lan' },
            { label: '阿聯酋', value: 'ARE', pinyin: 'a lian qiu' },
            { label: '英國', value: 'GBR', pinyin: 'ying guo' },
            { label: '美國', value: 'USA', pinyin: 'mei guo' },
            { label: '美國本土外小島嶼', value: 'UMI', pinyin: 'mei guo ben tu wai xiao dao yu' },
            { label: '烏拉圭', value: 'URY', pinyin: 'wu la gui' },
            { label: '烏茲別克斯坦', value: 'UZB', pinyin: 'wu zi bie ke si tan' },
            { label: '瓦努阿圖', value: 'VUT', pinyin: 'wa nu a tu' },
            { label: '委內瑞拉', value: 'VEN', pinyin: 'wei nei rui la' },
            { label: '越南', value: 'VNM', pinyin: 'yue nan' },
            { label: '英屬維爾京群島', value: 'VGB', pinyin: 'ying shu wei er jing qun dao' },
            { label: '美屬維爾京群島', value: 'VIR', pinyin: 'mei shu wei er jing qun dao' },
            { label: '瓦利斯和富圖納', value: 'WLF', pinyin: 'wa li si he fu tu na' },
            { label: '西撒哈拉', value: 'ESH', pinyin: 'xi sa ha la' },
            { label: '也門', value: 'YEM', pinyin: 'ye men' },
            { label: '贊比亞', value: 'ZMB', pinyin: 'zan bi ya' },
            { label: '津巴布韋', value: 'ZWE', pinyin: 'jing ba bu wei' },
        ];
    }

    getCountriesCodeEn() {
        return [
            { label: 'Hong Kong', value: 'HKG' },
            { label: 'China', value: 'CHN' },
            { label: 'Afghanistan', value: 'AFG' },
            { label: 'Åland Islands', value: 'ALA' },
            { label: 'Albania', value: 'ALB' },
            { label: 'Algeria', value: 'DZA' },
            { label: 'American Samoa', value: 'ASM' },
            { label: 'Andorra', value: 'AND' },
            { label: 'Angola', value: 'AGO' },
            { label: 'Anguilla', value: 'AIA' },
            { label: 'Antarctica', value: 'ATA' },
            { label: 'Antigua and Barbuda', value: 'ATG' },
            { label: 'Argentina', value: 'ARG' },
            { label: 'Armenia', value: 'ARM' },
            { label: 'Aruba', value: 'ABW' },
            { label: 'Australia', value: 'AUS' },
            { label: 'Austria', value: 'AUT' },
            { label: 'Azerbaijan', value: 'AZE' },
            { label: 'Bahamas', value: 'BHS' },
            { label: 'Bahrain', value: 'BHR' },
            { label: 'Bangladesh', value: 'BGD' },
            { label: 'Barbados', value: 'BRB' },
            { label: 'Belarus', value: 'BLR' },
            { label: 'Belgium', value: 'BEL' },
            { label: 'Belize', value: 'BLZ' },
            { label: 'Benin', value: 'BEN' },
            { label: 'Bermuda', value: 'BMU' },
            { label: 'Bhutan', value: 'BTN' },
            { label: 'Bolivia', value: 'BOL' },
            { label: 'Bosnia and Herzegovina', value: 'BIH' },
            { label: 'Botswana', value: 'BWA' },
            { label: 'Bouvet Island', value: 'BVT' },
            { label: 'Brazil', value: 'BRA' },
            { label: 'British Indian Ocean Territory', value: 'IOT' },
            { label: 'Brunei Darussalam', value: 'BRN' },
            { label: 'Bulgaria', value: 'BGR' },
            { label: 'Burkina Faso', value: 'BFA' },
            { label: 'Burundi', value: 'BDI' },
            { label: 'Cambodia', value: 'KHM' },
            { label: 'Cameroon', value: 'CMR' },
            { label: 'Canada', value: 'CAN' },
            { label: 'Cabo Verde', value: 'CPV' },
            { label: 'Cayman Islands', value: 'CYM' },
            { label: 'Central African Republic', value: 'CAF' },
            { label: 'Chad', value: 'TCD' },
            { label: 'Chile', value: 'CHL' },
            { label: 'Christmas Island', value: 'CXR' },
            { label: 'Cocos (Keeling) Islands', value: 'CCK' },
            { label: 'Colombia', value: 'COL' },
            { label: 'Comoros', value: 'COM' },
            { label: 'Congo', value: 'COG' },
            { label: 'Congo (Democratic Republic of the)', value: 'COD' },
            { label: 'Cook Islands', value: 'COK' },
            { label: 'Costa Rica', value: 'CRI' },
            { label: "Côte d'Ivoire", value: 'CIV' },
            { label: 'Croatia', value: 'HRV' },
            { label: 'Cuba', value: 'CUB' },
            { label: 'Cyprus', value: 'CYP' },
            { label: 'Czechia', value: 'CZE' },
            { label: 'Denmark', value: 'DNK' },
            { label: 'Djibouti', value: 'DJI' },
            { label: 'Dominica', value: 'DMA' },
            { label: 'Dominican Republic', value: 'DOM' },
            { label: 'Ecuador', value: 'ECU' },
            { label: 'Egypt', value: 'EGY' },
            { label: 'El Salvador', value: 'SLV' },
            { label: 'Equatorial Guinea', value: 'GNQ' },
            { label: 'Eritrea', value: 'ERI' },
            { label: 'Estonia', value: 'EST' },
            { label: 'Ethiopia', value: 'ETH' },
            { label: 'Falkland Islands (Malvinas)', value: 'FLK' },
            { label: 'Faroe Islands', value: 'FRO' },
            { label: 'Fiji', value: 'FJI' },
            { label: 'Finland', value: 'FIN' },
            { label: 'France', value: 'FRA' },
            { label: 'French Guiana', value: 'GUF' },
            { label: 'French Polynesia', value: 'PYF' },
            { label: 'French Southern Territories', value: 'ATF' },
            { label: 'Gabon', value: 'GAB' },
            { label: 'Gambia', value: 'GMB' },
            { label: 'Georgia', value: 'GEO' },
            { label: 'Germany', value: 'DEU' },
            { label: 'Ghana', value: 'GHA' },
            { label: 'Gibraltar', value: 'GIB' },
            { label: 'Greece', value: 'GRC' },
            { label: 'Greenland', value: 'GRL' },
            { label: 'Grenada', value: 'GRD' },
            { label: 'Guadeloupe', value: 'GLP' },
            { label: 'Guam', value: 'GUM' },
            { label: 'Guatemala', value: 'GTM' },
            { label: 'Guernsey', value: 'GGY' },
            { label: 'Guinea', value: 'GIN' },
            { label: 'Guinea-Bissau', value: 'GNB' },
            { label: 'Guyana', value: 'GUY' },
            { label: 'Haiti', value: 'HTI' },
            { label: 'Heard Island and McDonald Islands', value: 'HMD' },
            { label: 'Holy See', value: 'VAT' },
            { label: 'Honduras', value: 'HND' },
            { label: 'Hungary', value: 'HUN' },
            { label: 'Iceland', value: 'ISL' },
            { label: 'India', value: 'IND' },
            { label: 'Indonesia', value: 'IDN' },
            { label: 'Iran', value: 'IRN' },
            { label: 'Iraq', value: 'IRQ' },
            { label: 'Ireland', value: 'IRL' },
            { label: 'Israel', value: 'ISR' },
            { label: 'Italy', value: 'ITA' },
            { label: 'Jamaica', value: 'JAM' },
            { label: 'Japan', value: 'JPN' },
            { label: 'Jersey', value: 'JEY' },
            { label: 'Jordan', value: 'JOR' },
            { label: 'Kazakhstan', value: 'KAZ' },
            { label: 'Kenya', value: 'KEN' },
            { label: 'Kiribati', value: 'KIR' },
            { label: "Korea (Democratic People's Republic of)", value: 'PRK' },
            { label: 'Korea (Republic of)', value: 'KOR' },
            { label: 'Kuwait', value: 'KWT' },
            { label: 'Kyrgyzstan', value: 'KGZ' },
            { label: "Lao People's Democratic Republic", value: 'LAO' },
            { label: 'Latvia', value: 'LVA' },
            { label: 'Lebanon', value: 'LBN' },
            { label: 'Lesotho', value: 'LSO' },
            { label: 'Liberia', value: 'LBR' },
            { label: 'Libya', value: 'LBY' },
            { label: 'Liechtenstein', value: 'LIE' },
            { label: 'Lithuania', value: 'LTU' },
            { label: 'Luxembourg', value: 'LUX' },
            { label: 'Macao', value: 'MAC' },
            { label: 'Republika Severna Makedonija', value: 'MKD' },
            { label: 'Madagascar', value: 'MDG' },
            { label: 'Malawi', value: 'MWI' },
            { label: 'Malaysia', value: 'MYS' },
            { label: 'Maldives', value: 'MDV' },
            { label: 'Mali', value: 'MLI' },
            { label: 'Malta', value: 'MLT' },
            { label: 'Marshall Islands', value: 'MHL' },
            { label: 'Martinique', value: 'MTQ' },
            { label: 'Mauritania', value: 'MRT' },
            { label: 'Mauritius', value: 'MUS' },
            { label: 'Mayotte', value: 'MYT' },
            { label: 'Mexico', value: 'MEX' },
            { label: 'Micronesia (Federated States of)', value: 'FSM' },
            { label: 'Moldova (Republic of)', value: 'MDA' },
            { label: 'Monaco', value: 'MCO' },
            { label: 'Mongolia', value: 'MNG' },
            { label: 'Montenegro', value: 'MNE' },
            { label: 'Montserrat', value: 'MSR' },
            { label: 'Morocco', value: 'MAR' },
            { label: 'Mozambique', value: 'MOZ' },
            { label: 'Myanmar', value: 'MMR' },
            { label: 'Namibia', value: 'NAM' },
            { label: 'Nauru', value: 'NRU' },
            { label: 'Nepal', value: 'NPL' },
            { label: 'Netherlands', value: 'NLD' },
            { label: 'New Caledonia', value: 'NCL' },
            { label: 'New Zealand', value: 'NZL' },
            { label: 'Nicaragua', value: 'NIC' },
            { label: 'Niger', value: 'NER' },
            { label: 'Nigeria', value: 'NGA' },
            { label: 'Niue', value: 'NIU' },
            { label: 'Norfolk Island', value: 'NFK' },
            { label: 'Northern Mariana Islands', value: 'MNP' },
            { label: 'Norway', value: 'NOR' },
            { label: 'Oman', value: 'OMN' },
            { label: 'Pakistan', value: 'PAK' },
            { label: 'Palau', value: 'PLW' },
            { label: 'Palestine, State of', value: 'PSE' },
            { label: 'Panama', value: 'PAN' },
            { label: 'Papua New Guinea', value: 'PNG' },
            { label: 'Paraguay', value: 'PRY' },
            { label: 'Peru', value: 'PER' },
            { label: 'Philippines', value: 'PHL' },
            { label: 'Pitcairn', value: 'PCN' },
            { label: 'Poland', value: 'POL' },
            { label: 'Portugal', value: 'PRT' },
            { label: 'Puerto Rico', value: 'PRI' },
            { label: 'Qatar', value: 'QAT' },
            { label: 'Réunion', value: 'REU' },
            { label: 'Romania', value: 'ROU' },
            { label: 'Russian Federation', value: 'RUS' },
            { label: 'Rwanda', value: 'RWA' },
            { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SHN' },
            { label: 'Saint Kitts and Nevis', value: 'KNA' },
            { label: 'Saint Lucia', value: 'LCA' },
            { label: 'Saint Pierre and Miquelon', value: 'SPM' },
            { label: 'Saint Vincent and the Grenadines', value: 'VCT' },
            { label: 'Samoa', value: 'WSM' },
            { label: 'San Marino', value: 'SMR' },
            { label: 'Sao Tome and Principe', value: 'STP' },
            { label: 'Saudi Arabia', value: 'SAU' },
            { label: 'Senegal', value: 'SEN' },
            { label: 'Serbia', value: 'SRB' },
            { label: 'Seychelles', value: 'SYC' },
            { label: 'Sierra Leone', value: 'SLE' },
            { label: 'Singapore', value: 'SGP' },
            { label: 'Slovakia', value: 'SVK' },
            { label: 'Slovenia', value: 'SVN' },
            { label: 'Solomon Islands', value: 'SLB' },
            { label: 'Somalia', value: 'SOM' },
            { label: 'South Africa', value: 'ZAF' },
            { label: 'South Georgia and the South Sandwich Islands', value: 'SGS' },
            { label: 'Spain', value: 'ESP' },
            { label: 'Sri Lanka', value: 'LKA' },
            { label: 'Sudan', value: 'SDN' },
            { label: 'Suriname', value: 'SUR' },
            { label: 'Svalbard and Jan Mayen', value: 'SJM' },
            { label: 'Kingdom of Eswatini', value: 'SWZ' },
            { label: 'Sweden', value: 'SWE' },
            { label: 'Switzerland', value: 'CHE' },
            { label: 'Syrian Arab Republic', value: 'SYR' },
            { label: 'Taiwan', value: 'TWN' },
            { label: 'Tajikistan', value: 'TJK' },
            { label: 'Tanzania, United Republic of', value: 'TZA' },
            { label: 'Thailand', value: 'THA' },
            { label: 'Timor-Leste', value: 'TLS' },
            { label: 'Togo', value: 'TGO' },
            { label: 'Tokelau', value: 'TKL' },
            { label: 'Tonga', value: 'TON' },
            { label: 'Trinidad and Tobago', value: 'TTO' },
            { label: 'Tunisia', value: 'TUN' },
            { label: 'Turkey', value: 'TUR' },
            { label: 'Turkmenistan', value: 'TKM' },
            { label: 'Turks and Caicos Islands', value: 'TCA' },
            { label: 'Tuvalu', value: 'TUV' },
            { label: 'Uganda', value: 'UGA' },
            { label: 'Ukraine', value: 'UKR' },
            { label: 'United Arab Emirates', value: 'ARE' },
            { label: 'United Kingdom of Great Britain and Northern Ireland', value: 'GBR' },
            { label: 'United States of America', value: 'USA' },
            { label: 'United States Minor Outlying Islands', value: 'UMI' },
            { label: 'Uruguay', value: 'URY' },
            { label: 'Uzbekistan', value: 'UZB' },
            { label: 'Vanuatu', value: 'VUT' },
            { label: 'Venezuela (Bolivarian Republic of)', value: 'VEN' },
            { label: 'Viet Nam', value: 'VNM' },
            { label: 'Virgin Islands (British)', value: 'VGB' },
            { label: 'Virgin Islands (U.S.)', value: 'VIR' },
            { label: 'Wallis and Futuna', value: 'WLF' },
            { label: 'Western Sahara', value: 'ESH' },
            { label: 'Yemen', value: 'YEM' },
            { label: 'Zambia', value: 'ZMB' },
            { label: 'Zimbabwe', value: 'ZWE' },
        ];
    }
}

export default new InitWindowVariables();
