import React, { useEffect, useRef, useState } from 'react';
import {
    showLoading,
    hideLoading,
    showFail,
    showSuccess,
    hideToast,
    showToast,
} from '../../utils/toast';
import styles from './photo.module.scss';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { PictureOutlined, LeftOutlined } from '@ant-design/icons';
import { startCompress } from '../../utils/compressBase64';
import { withRouter } from 'react-router';
import { getUserMediaStream } from '../../utils/getUserMediaStream';

const Photo = ({ history }) => {
    const [videoHeight, setVideoHeight] = useState(0);
    const [fileList, setFileList] = useState([]);
    const ref = useRef(null);

    useEffect(() => {
        const video = document.getElementById('video');
        const rectangle = document.getElementById('capture-rectangle');
        const container = document.getElementById('container');
        const _canvas = document.createElement('canvas');
        _canvas.style.display = 'block';
        // _canvas.style.paddingBottom = '500px';

        getUserMediaStream(video)
            .then(() => {
                setVideoHeight(video.offsetHeight);
                startCapture();
            })
            .catch(err => {
                showFail({
                    text: '无法调起后置摄像头，请点击相册，手动上传身份证',
                    duration: 6,
                });
            });

        /**
         * 获取video中对应的真实size
         */
        function getRealSize() {
            const { videoHeight: vh, videoWidth: vw, offsetHeight: oh, offsetWidth: ow } = video;

            return {
                getHeight: height => {
                    return (vh / oh) * height;
                },
                getWidth: width => {
                    return (vw / ow) * width;
                },
            };
        }

        function isChildOf(child, parent) {
            var parentNode;
            if (child && parent) {
                parentNode = child.parentNode;
                while (parentNode) {
                    if (parent === parentNode) {
                        return true;
                    }
                    parentNode = parentNode.parentNode;
                }
            }
            return false;
        }

        function startCapture() {
            ref.current = setInterval(() => {
                const { getHeight, getWidth } = getRealSize();
                /** 获取框的位置 */
                const { left, top, width, height } = rectangle.getBoundingClientRect();

                /** 测试时预览 */
                // if (isChildOf(_canvas, container)) {
                //     container.removeChild(_canvas);
                // }
                // container.appendChild(_canvas);

                const context = _canvas.getContext('2d');
                _canvas.width = width;
                _canvas.height = height;

                context.drawImage(
                    video,
                    getWidth(left + window.scrollX),
                    getHeight(top + window.scrollY),
                    getWidth(width),
                    getHeight(height),
                    0,
                    0,
                    width,
                    height,
                );

                const base64 = _canvas.toDataURL('image/jpeg');
                // TODO 上传
            }, 200);
        }

        /** 防止内存泄露 */
        return () => clearInterval(ref.current);
    }, []);

    /** 只支持1张图片 */
    function updateUploadFiles(url) {
        let files = [];
        if (url) {
            files = [{ url }];
        }

        setFileList(files);
    }

    const __formatUploadFile2base64AndCompress = file => {
        const handleImgFileBase64 = file => {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = function () {
                    resolve(reader.result);
                };
            });
        };

        showLoading();
        handleImgFileBase64(file)
            .then(res => {
                if (file.size > 750 * 1334) {
                    showLoading('图片压缩中...');
                    return startCompress(res);
                } else {
                    return res;
                }
            })
            .then(res => {
                hideLoading();
                updateUploadFiles();
                // TODO 上传
                showSuccess({
                    text: '上传成功!',
                });
            })
            .catch(err => {
                console.error(err);
                hideLoading();
                showFail({
                    text: '上传失败',
                });
            });
    };

    const onChangeFile = event => {
        const files = event.target.files;
        if (files?.[0]) {
            __formatUploadFile2base64AndCompress(files[0]);
        }
    };

    const customUploadProps = {
        onChange: onChangeFile,
        accept: 'image/jpeg,image/jpg,image/png',
        // maxCount: props.maxCount,
        files: fileList,
    };

    const CustomUpload = customUploadProps => (
        <input className={styles['input']} type="file" {...customUploadProps} />
    );

    const onClickBack = () => {
        history.goBack();
    };

    return (
        <BasicWrapper>
            <div id="container" className={styles.container}>
                <LeftOutlined
                    style={{ fontSize: '0.555rem' }}
                    className={styles['back']}
                    onClick={onClickBack}
                />
                <video
                    id="video"
                    autoPlay
                    muted
                    playsInline
                    // webkit-playsinline
                    style={{
                        width: '100%',
                    }}
                ></video>

                <div className={styles['shadow-layer']} style={{ height: `${videoHeight}px` }}>
                    <div id="capture-rectangle" className={styles['capture-rectangle']}></div>

                    {/* <div className={styles['hold-tips']}>请拿稳镜头和身份证</div> */}
                    <div className={styles['hold-tips']}>请将身份证置于取景框内</div>
                </div>

                <div className={styles.tips}>注：请确保证件完整图片都置于框内</div>

                <div className={styles['gallery-container']}>
                    <CustomUpload {...customUploadProps} />
                    <PictureOutlined className={styles['icon']} />
                </div>
            </div>
        </BasicWrapper>
    );
};
export default withRouter(Photo);
// useEffect(() => {
//     navigator.mediaDevices
//         .getUserMedia({ audio: true, video: true })
//         .then(function (stream) {
//             var video = document.querySelector('video');

//             debugger;
//             // 旧的浏览器可能没有srcObject
//             if ('srcObject' in video) {
//                 debugger;
//                 video.srcObject = stream;
//             } else {
//                 debugger;
//                 // 防止在新的浏览器里使用它，应为它已经不再支持了
//                 // video.src = window.URL.createObjectURL(stream);
//             }
//             video.onloadedmetadata = function (e) {
//                 video.play();
//             };
//         })
//         .catch(function (err) {
//             console.log(err.name + ': ' + err.message);
//         });
// }, []);
