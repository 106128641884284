export const zh_TW = {
    next: '下一步',
    previous: '上一步',
    upload: '上傳',
    sample: '示例',
    enter: '請輸入',
    select: '請選擇',
    selected: '已選擇',
    errorToast: '出錯了',
    confirm: '確定',
    search: '搜索',

    'signDoc.next': '我已下載資料文件',

    'login.hello': '您好，',
    'login.welcome': '歡迎來到華興資本財富',
    'login.inputPhoneNumber': '請輸入手機號碼',
    'login.info': '請勾選底部條款及細則',

    'title.openact': '華興資本財富開戶',

    'verification.tip1': '請輸入驗證碼',
    'verification.tip2': '驗證碼已發送至',
    'verification.tip3': '未收到驗證碼？ {second}秒後重發',
    'verification.tip4': '重新發送驗證碼',

    'invitation.title': '請輸入邀請碼',
    'invitation.subtitle': '邀請碼由您的客戶經理提供',
    'invitation.error': '邀請碼錯誤，請聯系您的客戶經理',

    'guide.tip': '您暫無華興財富賬戶',
    'guide.cardtitle': '個人信息收集',
    'guide.carddesc': '請準備好身份證、護照等證件',

    'ProgressHeader.percent': '資料填寫進度',

    'CustomInput.placeholder': '請輸入',

    'CustomRadio.desc1': '您好，當前流程不支持美國公民或美國綠卡持有人進行信息收集',
    'CustomRadio.desc2first': '請聯系您的客戶經理了解詳細情況',
    'CustomRadio.desc2second': ' 了解詳細情況',
    'CustomRadio.cancel': '我知道了',
    'CustomRadio.confirm': '聯系客服',

    'schedule.tip': '重新填寫資料',

    'check.title': '尊敬的客戶：',
    'check.tip1':
        '感謝您選擇華興資本財富。為方便與閣下建立業務關系的流程，你可以在本平臺上填寫相關資料及提供所需文件，我們會於收集閣下之資料後作審核及通知閣下後續建立業務事宜。請詳細閱讀使用此平臺之[',
    'check.tip2': '條款及細則',
    'check.tip3': ']。',

    'AgreementDialog.tip1': '本人已閱讀並同意上述條款及細則',
    'AgreementDialog.tip2': '(請滑到底部)',
    'AgreementDialog.tip3': '請閱讀至底部',
};
