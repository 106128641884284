import { SET_CAN_USE_GET_USER_MEDIA, SET_IS_PC } from '../../constants/constants';

function device(
    state = {
        isPc: false,
        canUseGetUserMedia: false,
    },
    action,
) {
    const { type, canUseGetUserMedia, isPC } = action;

    if (type === SET_CAN_USE_GET_USER_MEDIA) {
        return {
            ...state,
            canUseGetUserMedia,
        };
    } else if (type === SET_IS_PC) {
        return {
            ...state,
            isPC,
        };
    } else {
        return state;
    }
}

export default device;
