console.log(process.env);
export const setting = {
    host:
        process.env.MY_ENV === 'development'
            ? 'https://hx.ilookcap.com/api/'
            : 'https://wmtool.crwealth.com/api',
    /** apis which don't need token */
    publicApi: ['/v1/user/send_cc'],
    /** "int, 客户端类型, 1-小程序, 2-H5", */
    client_type: 2,
    /** token过期时间 */
    // ttl: 1000 * 60 * 10,

    // TODO mock
    ttl: 1000 * 60 * 24 * 24 * 24 * 1000,
};

export const constantsText = {
    error: navigator.language.toLowerCase().indexOf('zh') !== -1 ? '出错了' : 'Error',
};
