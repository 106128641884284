import { withRouter } from 'react-router-dom';
import styles from './StepsWrapper.module.scss';
import ProgressHeader from '../../components/ProgressHeader/ProgressHeader';
import { useIntl } from 'react-intl';
import SwitchLangue from '../SwitchLangue/SwitchLangue';

const StepWrapper = ({ children = null, donePercent = 0, onClickBack, showBackButton }) => {
    const { messages } = useIntl();

    return (
        <div className={styles.container}>
            <SwitchLangue onClickBack={onClickBack} showBackButton={showBackButton} />
            <ProgressHeader childrenNodes={children} donePercent={donePercent} />
        </div>
    );
};
export default withRouter(StepWrapper);
