import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import SwitchLangue from '../../components/SwitchLangue/SwitchLangue';
import styles from './invitation.module.scss';
import HeaderLogo from '../../statics/header-logo.png';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import misc from '../../services/misc';

function Invitation({ history, location }) {
    const [inviteCode, setInviteCode] = useState('');
    const [error, setError] = useState(false);

    const validateCode = () => {
        misc.checkInviteCode({ invitation_code: inviteCode })
            .then(() => {
                history.push(location.state.targetPath);
            })
            .catch(() => {
                setError(true);
            });
    };

    const onInputChange = e => {
        let value = e.target.value;
        if (value !== inviteCode) {
            setError(false);
        }
        setInviteCode(value);
    };

    const buttonDisableClass = useMemo(() => {
        if (inviteCode.length < 6 || error) {
            return styles['next-step-btn-disable'];
        } else {
            return '';
        }
    }, [inviteCode, error]);

    return (
        <BasicWrapper>
            <SwitchLangue />
            <div className={styles.wrapper}>
                <img src={HeaderLogo} alt="logo" className={styles['product-logo']} />
                <h1 className={styles.title}>
                    <FormattedMessage id="invitation.title" />
                </h1>
                <div className={styles.underlane} />
                <p className={styles.subtitle}>
                    <FormattedMessage id="invitation.subtitle" />
                </p>
                <Input
                    className={`${styles['code-input']} ${error ? styles['code-input-error'] : ''}`}
                    value={inviteCode}
                    onChange={onInputChange}
                    maxLength={6}
                />
                <p className={styles['error-msg']} style={{ opacity: error ? 1 : 0 }}>
                    <FormattedMessage id="invitation.error" />
                </p>
                <button
                    className={`${styles['next-step-btn']} ${buttonDisableClass}`}
                    onClick={() => validateCode()}
                >
                    <FormattedMessage id="next" />
                </button>
            </div>
        </BasicWrapper>
    );
}

export default withRouter(connect(state => ({ lang: state.config.lang }))(Invitation));
