import { createStore, applyMiddleware } from 'redux';
import reducer from '../reducers/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { setIsPc } from '../actions/actions';
import { isPC } from '../utils/userAgent';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

store.dispatch(setIsPc(isPC()));

export default store;
