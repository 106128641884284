import { Drawer } from 'antd';
import { useState, useMemo, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Fuse from 'fuse.js';

import styles from './MultipleSelector.module.scss';
import REC_ICON from '../../../../statics/select-rectangle.png';
import PICKED_ICON from '../../../../statics/picked.png';
import CLOSE_ICON from '../../../../statics/close.png';
import { debounce } from '../../../../utils/utils';

/**
 * @params { string[] } value
 */
export default ({ dataSource, value = [], onChange }) => {
    const scrollBar = useRef(null);
    const options = useRef(null);

    const [customScrollThumbMarginTop, setCustomScrollThumbMarginTop] = useState(116);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(value);
    const [searchKey, setSearchKey] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: 'search' });

    const mockScrollBarCallBack = event => {
        setCustomScrollThumbMarginTop(
            ((event.target.scrollTop / 60) * 473) /
                (searchResult.length ? searchResult.length : 244) +
                116,
        );
    };

    const selectedOptionLabels = useMemo(
        () =>
            selectedOptions
                .map(option => dataSource.find(v => v.value === option))
                .filter(v => v)
                .map(v => v.label),
        [selectedOptions],
    );
    const selectedOptionsCount = selectedOptions.length;

    const fuse = useMemo(
        () =>
            new Fuse(dataSource, {
                includeScore: true,
                keys: ['label', 'value', 'pinyin'],
            }),
        [dataSource],
    );

    const onClickOption = value => {
        const nextSelectedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter(option => option !== value)
            : selectedOptions.concat(value);
        setSelectedOptions(nextSelectedOptions);
        setSearchResult([]);
        setSearchKey('');
    };

    useEffect(() => {
        scrollBar.current !== null &&
            (scrollBar.current.scrollLeft = scrollBar.current.scrollWidth);
    });

    useEffect(() => {
        if (options.current) {
            options.current.addEventListener('scroll', mockScrollBarCallBack);
        }
        return () => {
            if (options.current) {
                // no other ways when you always want to force show the scrollbar, removeEventListener to forbid poor performance
                options.current.removeEventListener('scroll', mockScrollBarCallBack);
            }
        };
    });

    const handleClickSelectInput = () => {
        setIsShowPopup(true);
    };

    const onConfirm = () => {
        onChange([...selectedOptions]);
        setIsShowPopup(false);
    };

    const onCloseDrawer = () => {
        onChange([...selectedOptions]);
        setIsShowPopup(false);
    };

    const search = useMemo(
        () =>
            debounce(key => {
                const nextSearchResult = fuse
                    .search(key)
                    .sort((a, b) => a.item.score - b.item.score)
                    .map(v => v.item);
                setSearchResult(nextSearchResult);
                options.current.scrollTop = 0;
            }, 500),
        [],
    );

    const handleChange = event => {
        const key = event.target.value;
        setSearchKey(key);
        /** fuse match required string */
        search(String(key));
    };

    const handleKeyDown = event => {
        if (event.keyCode == 8 && !searchKey) {
            setSelectedOptions(selectedOptions.slice(0, -1));
        }
    };

    const onClear = () => {
        setSelectedOptions([]);
        setSearchResult([]);
        setSearchKey('');
    };

    return (
        <>
            <div className={styles['select-input']} onClick={handleClickSelectInput}>
                {selectedOptionsCount ? (
                    <div className={styles['selected-items']}>
                        {selectedOptionLabels.map(selectedOptionLabel => (
                            <span className={styles['item']} key={selectedOptionLabel}>
                                {selectedOptionLabel}
                            </span>
                        ))}
                    </div>
                ) : (
                    <span className={styles.placeholder}>
                        <FormattedMessage id="select"></FormattedMessage>
                    </span>
                )}
                <img src={REC_ICON} className={styles['select-rec']} />
            </div>
            <Drawer
                placement="bottom"
                closable={false}
                onClose={onCloseDrawer}
                visible={isShowPopup}
                height={'75%'}
                bodyStyle={{
                    padding: 0,
                    overflow: 'hidden',
                }}
                className={'bottom-drawer-has-radius'}
            >
                <div className={styles['popup-container']}>
                    <div className={styles.tips}>
                        <FormattedMessage id="selected"></FormattedMessage>
                        <span className={styles['selected-count']}>{selectedOptionsCount}</span>
                        <div className={styles['confirm-text']} onClick={onConfirm}>
                            <FormattedMessage id="confirm"></FormattedMessage>
                        </div>
                    </div>
                    <div className={styles['search-input']}>
                        <div className={styles['selected-items']} ref={scrollBar}>
                            {selectedOptionLabels.map(selectedOptionLabel => (
                                <span className={styles['item']} key={selectedOptionLabel}>
                                    {selectedOptionLabel}
                                </span>
                            ))}
                        </div>
                        <div className={styles['input-wrap']}>
                            <input
                                type="text"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                value={searchKey}
                                placeholder={placeholder}
                            />
                        </div>
                        <div style={{ marginLeft: 'auto' }} onClick={onClear}>
                            <img src={CLOSE_ICON} className={styles['close']} />
                        </div>
                    </div>
                    {/*
                     * why mock scrollbar?
                     * Mobile browsers ban custom scrollbar, and iscroll(it's a repo for mock scrollbar, such others also use custom scrollbar so cannot working in mobile) has poor performance in formily, this FC will reRender 16 times when update.
                     */}
                    <div
                        style={{
                            width: '3px',
                            height: '20px',
                            borderRadius: '4px',
                            position: 'absolute',
                            background: '#3a3a3a',
                            right: '5px',
                            top: customScrollThumbMarginTop + 'px',
                        }}
                    ></div>
                    <div className={styles['options']} ref={options}>
                        {searchResult.length > 0
                            ? searchResult.map((v, index) => (
                                  <div
                                      key={index}
                                      className={styles['option']}
                                      onClick={() => onClickOption(v.value)}
                                  >
                                      {v.label}
                                      {selectedOptions.includes(v.value) && (
                                          <img src={PICKED_ICON} className={styles['picked']} />
                                      )}
                                  </div>
                              ))
                            : Array.isArray(dataSource) &&
                              dataSource.map((v, index) => (
                                  <div
                                      key={index}
                                      className={styles['option']}
                                      onClick={() => onClickOption(v.value)}
                                  >
                                      {v.label}
                                      {selectedOptions.includes(v.value) && (
                                          <img src={PICKED_ICON} className={styles['picked']} />
                                      )}
                                  </div>
                              ))}
                    </div>
                </div>
            </Drawer>
        </>
    );
};
