import Verification from '../pages/verification/verification';
import Invitation from '../pages/invitation/invitation';
import Login from '../pages/login/login';
import Schedule from '../pages/schedule/schedule';
import Guide from '../pages/guide/guide';

import Openact from '../pages/openact/openact';
import Photo from '../pages/photo/photo';
import RedirectPage from '../pages/redirect/redirect';

const ValidatingRoutesList = [
    {
        path: '/openact/:formId?/:stepId?',
        component: Openact,
        exact: true,
    },
    {
        path: '/schedule',
        component: Schedule,
        exact: true,
    },
    {
        path: '/guide/:countryCode/:tel',
        component: Guide,
        exact: true,
    },
];

/** 不需要鉴权 */
const NonValidatingRoutesList = [
    {
        path: '/login',
        component: Login,
        exact: true,
    },
    {
        path: '/verification/:countryCode/:tel',
        component: Verification,
        exact: true,
    },
    {
        path: '/invitation',
        component: Invitation,
    },
    {
        path: '/photo',
        component: Photo,
        exact: true,
    },
    {
        path: '/redirectpage/:formId?/:stepId?',
        component: RedirectPage,
        exact: true,
    },
];

export { ValidatingRoutesList, NonValidatingRoutesList };
