import { useState, useMemo, useRef } from 'react';
import styles from './CustomInput.module.scss';
import { Input } from 'antd';
import { useIntl } from 'react-intl';

/**
 *
 * @param {*} props 自定义autoToUpperCase
 * @returns
 */
const CustomAutoToUpperCaseInput = props => {
    const { onBlur, onChange, onFocus, style, value: propsValue, autoToUpperCase, ...rest } = props;
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: 'enter' });

    const [inputValue, setInputValue] = useState(() => (propsValue ? propsValue : ''));

    const componentProps = { ...rest };

    const onChangeInput = e => {
        /**
         * toUpperCase方案有问题，会导致中文无法输入，hack假input
         */
        let value = e.target.value;
        let fieldValue;

        if (autoToUpperCase) {
            fieldValue = value.toUpperCase();
        } else {
            fieldValue = value;
        }
        /** 表单内部value不变，用css属性给予假反馈 */
        setInputValue(value);
        /** 只改变外层form提交时的fields value */
        onChange(fieldValue);
    };

    const onInputBlur = () => {
        onBlur();
    };

    return (
        <Input
            className={styles.container}
            onChange={onChangeInput}
            value={inputValue}
            style={Object.assign(
                {
                    background: '#F6F6F7',
                    borderRadius: '0.266666667rem',
                    minHeight: '1.28rem',
                    color: '#1E1F20',
                    margin: '0.32rem 0 0',
                    fontSize: '0.426666667rem',
                },
                style,
                autoToUpperCase
                    ? {
                          textTransform: 'uppercase',
                      }
                    : {},
            )}
            placeholder={placeholder}
            onBlur={onInputBlur}
            {...componentProps}
        />
    );
};

export default CustomAutoToUpperCaseInput;
