import styles from './CustomCheckbox.module.scss';
import { Checkbox } from 'antd';

const CustomCheckbox = ({ value, onChange, label }) => {
    return (
        <>
            <Checkbox className={styles['check-clause']} value={value} onChange={onChange}>
                <span className={styles['check-text']}>{label}</span>
            </Checkbox>
        </>
    );
};

export default CustomCheckbox;
