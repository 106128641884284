import axios from '../utils/o-axios'

class Openact {
    getAgreement = (data = {}) => {
        return axios.post('/v1/openact/agreement', {
            data,
        });
    }

    loadForm = (data = {}) => {
        return axios.post('/v1/openact/load_form', {
            data,
        });
    }

    submitForm = (data = {}) => {
        return axios.post('/v1/openact/submit_form', {
            data,
        });
    }

    getStatus = (data = {}) => {
        return axios.post('/v1/openact/status', {
            data,
        });
    }
}

export default new Openact()


