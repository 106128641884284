import { Redirect, useHistory, withRouter } from 'react-router-dom';

/** history.replace()跳转会有闭包，暂时用中间页方式进行规避 */
const Redirectpage = ({ match }) => {
    const formId = match.params.formId;
    const stepId = match.params.stepId;
    return (
        <>
            <Redirect to={`/openact/${formId}/${stepId}`} />
        </>
    );
};

export default withRouter(Redirectpage);
