import { useState } from 'react';
import { showLoading, hideLoading, showSuccess, showFail } from '../../utils/toast';
import { startCompress } from '../../utils/compressBase64';
import { PlusOutlined, LoadingOutlined, CloseCircleTwoTone } from '@ant-design/icons';
import { Spin } from 'antd';

/** CHN身份证正面，后续后端有空可以做到后端传base64 */
import idFrontExample from '../../statics/id-front-example.png';
/** CHN身份证背面 */
import idBackExample from '../../statics/id-back-example.png';
import HK_backend from '../../statics/HK_backend.png';
import HK_front from '../../statics/HK_front.png';
import CHN_passport_front from '../../statics/CHN_passport_front.png';

import styles from './customUploadMultiple.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// idCardExample
const uploadExampleMap = {
    /** 老版本设计比较简单，字段名字没有区分开  */
    // CHN身份证正面
    front: idFrontExample,
    // CHN身份证背面
    backend: idBackExample,

    // HK身份证正面
    HK_backend: HK_backend,
    // HK身份证背面
    HK_front: HK_front,
    // CHN护照正面
    CHN_passport_front: CHN_passport_front,
};

/**
 * 注：支持多张图片上传
 * @param {*} props
 * idCardExample: 展示身份证示例的面 front backend
 * value: 图片默认值
 * accept: 上传文件类型
 */
export default function CustomUploadComponent(props) {
    const [fileList, setFileList] = useState(() =>
        Array.isArray(props.value) && Array.isArray(props.value[0]?.url) ? props.value[0].url : [],
    );
    const [isLoading, setIsLoading] = useState(false);
    const device = useSelector(state => state.device);
    const history = useHistory();
    /** 是否为pdf */
    const [isPdf, setIsPdf] = useState(false);
    const [pdfName, setPdfName] = useState('');

    /** 只支持1张图片 */
    function updateUploadFields(url) {
        const files = [...fileList, url];
        console.log(files);
        /** 更新表单数据 */
        props.onChange(files);
        setFileList(files);
    }

    const __formatUploadFile2base64AndCompress = file => {
        const handleImgFileBase64 = file => {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = function () {
                    resolve(reader.result);
                };
            });
        };

        setIsLoading(true);
        showLoading('图片上传中...');
        handleImgFileBase64(file)
            .then(res => {
                if (file.size > 750 * 1334) {
                    return startCompress(res);
                } else {
                    return res;
                }
            })
            .then(res => {
                updateUploadFields(res);
                hideLoading();
                showSuccess({
                    text: '上传成功!',
                });
            })
            .catch(err => {
                console.error(err);
                hideLoading();
                showFail({
                    text: '上传失败',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChangeFile = event => {
        const files = event.target.files;
        if (files?.[0]?.type === 'application/pdf') {
            setIsPdf(true);
            setPdfName(files?.[0]?.name);
        } else {
            setIsPdf(false);
            setPdfName('');
        }
        if (props.accept?.indexOf(files?.[0]?.type) === -1) {
            return showFail({
                text: '文件上传格式错误',
            });
        }
        if (files?.[0]) {
            __formatUploadFile2base64AndCompress(files[0]);
        }
    };

    const onClickCustomTakePhoto = () => {
        history.push('/photo');
    };

    /** ------ components start ------ */
    const Placeholder = () =>
        isLoading ? (
            <Spin tip="上传中..." className={styles['spin']} indicator={antIcon} />
        ) : (
            <div className={styles.placeholder}>
                <PlusOutlined />
                <span className={styles['desc']}>
                    <FormattedMessage id="upload"></FormattedMessage>
                </span>
            </div>
        );

    /*
     * 类型为身份证时，如果上传列表为空，展示示例
     */
    const UploadExample = () =>
        !!props.idCardExample &&
        fileList.length === 0 && (
            <div className={styles['example']}>
                <span>
                    <FormattedMessage id="sample"></FormattedMessage>：
                </span>
                <img
                    src={uploadExampleMap[props.idCardExample]}
                    className={styles['example-img']}
                ></img>
            </div>
        );

    const DeleteFile = index => {
        setFileList(fileList.filter((_, eleIndex) => eleIndex !== index));
    };

    const FileList = () =>
        fileList.map((v, index) => {
            return (
                <div
                    style={{
                        backgroundImage: `url(${v})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        position: 'relative',
                        zIndex: 1,
                    }}
                    src={v}
                    key={index}
                    className={styles['img']}
                >
                    <CloseCircleTwoTone
                        twoToneColor="#c30000"
                        className={styles['delete-img']}
                        onClick={() => DeleteFile(index)}
                    />
                </div>
            );
        });

    const PdfFile = () => {
        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                className={styles['img']}
            >
                {pdfName}
            </div>
        );
    };

    const CustomUploadProps = {
        onChange: onChangeFile,
        accept: props.accept || '',
        // maxCount: props.maxCount,
        files: fileList,
    };

    const CustomUpload = CustomUploadProps => (
        <input className={styles['input']} type="file" multiple {...CustomUploadProps} />
    );

    const Remarks = () => {
        return (
            <div className={styles['remarks-container']}>
                {props.remarks?.map((remark, index) => (
                    <div key={index} className={styles.remark}>
                        {remark}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <UploadExample />
            <Remarks />
            <div className={styles['upload']}>
                <div className={styles['img-container']}>
                    {isPdf ? <PdfFile /> : <FileList />}
                    <div
                        className={styles['input-container']}
                        style={fileList.length >= 10 ? { display: 'none' } : {}}
                    >
                        {device.canUseGetUserMedia &&
                        !device.isPC &&
                        props.needRealTimeRecognition ? (
                            <div className={styles['input']} onClick={onClickCustomTakePhoto}></div>
                        ) : (
                            fileList.length < 10 && <CustomUpload {...CustomUploadProps} />
                        )}
                        {fileList.length < 10 && <Placeholder />}
                    </div>
                </div>
            </div>
        </div>
    );
}
