export function debounce(fn, delay = 200, atBegin = true) {
    let timer = null,
        last = 0,
        during;
    return function () {
        let self = this,
            args = arguments;
        var exec = function () {
            fn.apply(self, args);
        };
        if (atBegin && !timer) {
            exec();
            atBegin = false;
        } else {
            during = Date.now() - last;
            if (during > delay) {
                exec();
            } else {
                if (timer) clearTimeout(timer);
                timer = setTimeout(function () {
                    exec();
                }, delay);
            }
        }
        last = Date.now();
    };
}

export function throttle(func, freq, immediate) {
    // immediate = {
    //     true: "第一次回调立即触发，停止后不再触发",
    //     false: "第一次回调freq秒后触发，停止后依然会触发一次"
    // }
    let prev = 0,
        timeout;
    return immediate
        ? function (...args) {
              const self = this;
              let now = +new Date();
              if (now - prev > freq) {
                  func.apply(self, args);
                  prev = now;
              }
          }
        : function (...args) {
              const self = this;
              if (!timeout)
                  timeout = setTimeout(() => {
                      func.apply(self, args);
                      timeout = null;
                  }, freq);
          };
}
