// en_US.js
const en_US = {
    next: 'Next',
    previous: 'Previous',
    upload: 'Upload',
    sample: 'Sample',
    enter: 'Please enter',
    select: 'Please select',
    selected: 'Selected',
    errorToast: 'Error',
    confirm: 'confirm',
    search: 'search',

    'signDoc.next': 'I HAVE DOWNLOADED THE FILES',

    'login.hello': 'Hello!',
    'login.welcome': 'Welcome to CR Wealth',
    'login.inputPhoneNumber': 'Please enter your mobile phone number',
    'login.info': 'Please check the bottom Terms and Conditions',

    'title.openact': 'Open an CR Wealth account',

    'verification.tip1': 'Please enter verification code',
    'verification.tip2': 'Verification code has been sent to ',
    'verification.tip3': 'Verification code not received?  Resend code after {second}s',
    'verification.tip4': 'resend verification code',

    'invitation.title': 'Please enter the invitation code',
    'invitation.subtitle': 'The invitation code shall be provided by your client manager',
    'invitation.error': 'The invitation code is invalid, please contact your client manager',

    'guide.tip': 'You do not have a CR Wealth account yet',
    'guide.cardtitle': 'Information collection',
    'guide.carddesc': 'Please prepare your ID card and Passport',

    'ProgressHeader.percent': 'progress',

    'CustomInput.placeholder': 'Please enter',

    'CustomRadio.desc1':
        'Hello, the current process does not support U.S. citizens or U.S. green card holders to continue.',
    'CustomRadio.desc2first': 'Please contact our customer service ',
    'CustomRadio.desc2second': ' for detail.',
    'CustomRadio.cancel': 'Cancel',
    'CustomRadio.confirm': 'Contact us',

    'schedule.tip': 'Refill the document',

    'check.title': 'Dear Valued Customer,',
    'check.tip1':
        'Thank you for choosing CR Wealth. In order to facilitate us to establish business relationship, you can fill in your information on this platform and provide the required documents. We will review your application after data collection and will notify you as soon as possible after our review. Please read through [',
    'check.tip2': 'the Terms and Conditions',
    'check.tip3': '] of using this platform carefully.',

    'AgreementDialog.tip1': 'I have read and agree to the above Terms and Conditions',
    'AgreementDialog.tip2': ' (please slide to the bottom)',
    'AgreementDialog.tip3': 'Please slide to the bottom',
};
export default en_US;
