import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
/** 测试本地json，只用来测试，和线上版本JSON不一样！ */
// import schema from './p01.json';
// import schema from './p02.json';
// import schema from './select.json';
// import schema from './p03.json';
// import schema from './p05.json';
// import schema from './1.6.json';
// import schema from './3.json';
// import schema from './4.json';
// import schema from './12.json';
// import schema from './a.json';
// import schema from './26.json';
import schema from './p投资经验.json';
// import schema from './upload_doc.json';
// import schema from './ptest.json';
// import schema from './pcustom.json';
// import schema from './ppdf.json';
// import schema from './CustomGroupSelect.json';

import {
    SchemaForm,
    SchemaMarkupField as Field,
    createFormActions,
    createAsyncFormActions, // 垃圾api，会导致linkages失效
} from '@formily/antd'; // 或者 @formily/next
import { forceFocusOnFirstValidatingFailedEle } from '../../utils/jqueryUtils';
import { isValueEmpty } from '../../utils/judge';
import { startCompress } from '../../utils/compressBase64';
import { formatUploadFields } from '../../utils/formatFieldsHandler';
import { Skeleton, Button, Modal, Checkbox as CheckBox } from 'antd';
import LinkIcon from '../../statics/invoice-accounting.png';

import styles from './openact.module.scss';
import StepsWrapper from '../../components/StepWrapper/StepsWrapper';
import openact from '../../services/openact';
import { FormattedMessage } from 'react-intl';

import {
    Radio,
    Checkbox,
    DatePicker,
    // NumberPicker,
    // TimePicker,
    Upload,
    // Switch,
    // Range,
    // Transfer,
    // Rating,
} from '@formily/antd-components'; // 或者@formily/next-components
import { hideLoading, showLoading, showToast } from '../../utils/toast';

import CustomUploadSingle from '../../custom-components/customUploadSingle/customUploadSingle';
import CustomUploadMultiple from '../../custom-components/customUploadMultiple/customUploadMultiple';
import CustomInput from '../../custom-components/CustomInput/CustomInput';
import CustomRadio from '../../custom-components/CustomRadio/CustomRadio';
import CustomCheckbox from '../../custom-components/CustomCheckbox';
// import CustomSelectGroups from '../../custom-components/CustomSelectGroups/CustomSelectGroups';
// import CustomOpenDialogWhenTappingLink from '../../custom-components/CustomOpenDialogWhenTappingLink/CustomOpenDialogWhenTappingLink';
import Input from '../../custom-components/Input/Input';
import TextArea from '../../custom-components/Textarea/Textarea';
import Select from '../../custom-components/Select/Select';
import AppendixDialog from '../../custom-components/AppendixDialog/AppendixDialog';

import { getUserMediaStream } from '../../utils/getUserMediaStream';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setCanUseGetUserMedia } from '../../actions/actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import initWindowVariables from '../../initWindowVariables';

const actions = createFormActions();
/**
 * 2021.6.8 注，目前真实正在使用的组件，只有这些
 *
CustomRadio
CustomInput
CustomUploadSingle
Input
Radio
Checkbox
Textarea
DatePicker
Card
Select
 */

const components = {
    /** 自定义Radio */
    CustomRadio,
    /** 自定义Input */
    CustomInput,
    /** 自定义upload */
    CustomUploadSingle,
    CustomUploadMultiple,
    /**
     * 附录弹窗
     */
    AppendixDialog,
    /** 带分组的Select */
    // CustomSelectGroups,
    /** 点击文字打开协议弹窗 */
    // CustomOpenDialogWhenTappingLink,
    Input,
    Radio: Radio.Group,
    Checkbox: Checkbox.Group,
    TextArea,
    CustomCheckbox,
    // NumberPicker,
    Select,
    // Switch,
    DatePicker,
    // DateRangePicker: DatePicker.RangePicker,
    // YearPicker: DatePicker.YearPicker,
    // MonthPicker: DatePicker.MonthPicker,
    // WeekPicker: DatePicker.WeekPicker,
    // TimePicker,
    // TimeRangePicker: TimePicker.RangePicker,
    Upload,
    // Range,
    // Rating,
    // Transfer,
};

const submitForm = ({ step_id, form_id, fields }) => {
    return openact.submitForm({
        step_id,
        form_id,
        fields,
    });
};

const schemaFormatter = schema => {
    if (schema.properties) {
        for (let key in schema.properties) {
            if (Object.prototype.hasOwnProperty.call(schema.properties, key)) {
                const item = schema.properties[key];
                if (item && item.tips) {
                    if (item.title) {
                        item.title = (
                            <span className={styles['title-wrapper']}>
                                <span className={styles.title}>{item.title}</span>
                                <ExclamationCircleOutlined
                                    className={styles.icon}
                                    onClick={() => {
                                        Modal.info({
                                            maskClosable: true,
                                            centered: true,
                                            className: styles['modal-info'],
                                            content: (
                                                <span className={styles.content}>{item.tips}</span>
                                            ),
                                        });
                                    }}
                                />
                            </span>
                        );
                    } else if (item.name) {
                        item.enum[0].label = (
                            <span className={styles['title-wrapper']}>
                                <span className={styles.title}>{item.enum[0].label}</span>
                                <ExclamationCircleOutlined
                                    className={styles.icon}
                                    onClick={() => {
                                        Modal.info({
                                            maskClosable: true,
                                            centered: true,
                                            className: styles['modal-info'],
                                            content: (
                                                <span className={styles.content}>{item.tips}</span>
                                            ),
                                        });
                                    }}
                                />
                            </span>
                        );
                    }
                }
                schemaFormatter(item);
            }
        }
    }
    return schema;
};

const loadForm = data => {
    return openact.loadForm(data);
};

const createRichTextUtils = history => {
    return {
        text(...args) {
            return React.createElement('span', {}, ...args);
        },
        // a函数第三个参数是frontText，代表链接前面的文案，endText代表链接后面的文案
        a(text, href, frontText = '', endText = '', target = '_self') {
            let aProps = {
                href,
                target,
            };

            return (
                <div>
                    {!!frontText && <span style={{ display: 'inline-block' }}>{frontText}</span>}

                    {!!text && (
                        <div className={styles.link}>
                            <img className={styles.linkIcon} src={LinkIcon} />
                            <a {...aProps} className={styles['link-a']}>
                                {text}
                            </a>
                        </div>
                    )}
                    {!!endText && <span style={{ display: 'inline-block' }}>{endText}</span>}
                </div>
            );
        },
        toBase64() {
            return options => {
                showLoading();
                const handleImgFileBase64 = file => {
                    return new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);

                        reader.onloadend = function () {
                            resolve(reader.result);
                        };
                    });
                };

                handleImgFileBase64(options.file)
                    .then(res => {
                        if (options.file.size > 750 * 1334) {
                            showLoading('compressing...');
                            return startCompress(res);
                        } else {
                            return res;
                        }
                    })
                    .then(res => {
                        options.onSuccess({ url: res }, res);
                        hideLoading();
                    })
                    .catch(err => console.error(err))
                    .finally(() => hideLoading());
            };
        },
    };
};

const parseLoadFormData = data => {
    return {
        progress: data.progress,
        basicTitle: data.title,
        schema: schemaFormatter(JSON.parse(data.content ?? '{}')),
        // schema: JSON.parse(d),
        pre_form_id: data.pre_form_id,
        next_form_id: data.next_form_id,
        step_id: data.step_id,
    };
};

const Openact = ({ history, match, setCanUseGetUserMedia, lang }) => {
    const formId = match.params.formId ?? 'form_upload_idcard';
    const stepId = match.params.stepId || 'step_info';
    const [formData, setFormData] = useState({});
    const [ready, setReady] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const loadFormData = useCallback(() => {
        loadForm({
            form_id: formId,
            step_id: stepId,
        })
            .then(res => {
                setReady(true);
                setFormData(parseLoadFormData(res.data));
                onCheckCanClickNextStep();
                hideLoading();
            })
            .catch(err => {
                console.error(err);
                showToast(err.msg);
            });
    }, [formId, stepId]);
    /**
     * 当页面卸载时，强制保存内容，暂时该逻辑还未投入使用
     */
    const __saveFormDataWhenUnmounted = useCallback(() => {
        const formGraphInstance = actions.getFormGraph();
        const submitFields = {};

        if (typeof formGraphInstance === 'object' && formGraphInstance !== null) {
            Object.keys(formGraphInstance)?.forEach(key => {
                /** key可能为'' */
                if (key) {
                    const field = formGraphInstance[key];
                    if (
                        field.visible &&
                        (!isValueEmpty(field.values?.[0]) ||
                            (Array.isArray(field.values?.[0]) &&
                                !isValueEmpty(field.values?.[0]?.[0]))) /** 针对上传文件情况 */
                    ) {
                        if (Array.isArray(field.values?.[0])) {
                            !isValueEmpty(field.values?.[0]?.[0]) &&
                                (submitFields[key] = field.values?.[0]?.[0]);
                        } else {
                            !isValueEmpty(field.values?.[0]) &&
                                (submitFields[key] = field.value?.[0]);
                        }
                    }
                }
            });
        }
        // TODO 调用接口
    }, []);

    const unmounted = () => {
        __saveFormDataWhenUnmounted();
    };

    useEffect(() => {
        /** 测试是否可以使用getUserMedia api */
        // const video = document.getElementById('video');
        showLoading();
        // if (formId === 'form_upload_idcard') {
        //     /** 检测是否有api能力 */
        //     getUserMediaStream(video)
        //         .then(() => {
        //             setCanUseGetUserMedia(true);
        //         })
        //         .catch(() => {
        //             setCanUseGetUserMedia(false);
        //         })
        //         .then(() => {
        //             loadFormData();
        //         });
        // } else {
        loadFormData();
        // }
        // return () => unmounted();
        // 语言切换的时候也需要reload
    }, [loadFormData, lang]);
    useEffect(() => {
        initWindowVariables.init(lang);
    }, [lang]);
    const onSubmitTest = fields => {
        fields = formatUploadFields(fields);

        console.log(fields);
        // history.push('/openact/form_basic/step_info');
    };

    const onSubmit = fields => {
        showLoading();
        /**
         * handle Upload fields, transform default upload component Array structure to String
         */
        fields = formatUploadFields(fields);

        submitForm({
            form_id: formId,
            step_id: stepId,
            fields,
        })
            .then(res => {
                /** if the steps are completed */
                if (res.data.is_completed === 1) {
                    hideLoading();
                    history.replace('/schedule');
                } else {
                    hideLoading();
                    /** 直接replace到openact会存在问题，增加中转页 */
                    history.replace(`/redirectpage/${res.data.next_form_id}/${res.data.step_id}`);
                }
            })
            .catch(err => showToast(err.msg));
    };

    const onValidateFailed = () => {
        forceFocusOnFirstValidatingFailedEle();
    };

    const onBackToPreviousPage = () => {
        history.replace(`/redirectpage/${formData.pre_form_id}/${formData.step_id}`);
    };
    /**
     * 检查 下一步 是否可点击
     */
    const onCheckCanClickNextStep = () => {
        const formGraphInstance = actions.getFormGraph();
        if (process.env.NODE_ENV === 'development') {
            console.log(formGraphInstance);
        }

        /**
         * 第一步，当前只是特殊业务场景，用来判断用户是否选择美国公民，如果为美国公民，则会弹出toast，并且终止流程步骤
         *
         * 之后，此处可以通过json字段传入customradio时，进行动态扩展
         */
        // if (typeof formGraphInstance === 'object' && formGraphInstance !== null) {
        //     let terminalValueAmount = 0;
        //     Object.keys(formGraphInstance)?.forEach(key => {
        //         /** key可能为'' */
        //         if (key) {
        //             const fields = formGraphInstance[key];

        //             if (
        //                 fields?.props?.['x-component'] === 'customradio' &&
        //                 fields?.props?.['x-component-props']?.terminalValue?.includes(
        //                     fields?.values?.[0],
        //                 )
        //             ) {
        //                 terminalValueAmount++;
        //             }
        //         }
        //     });
        //     if (terminalValueAmount > 0) {
        //         /** 直接终止流程 */
        //         return setIsDisabled(true);
        //     } else {
        //         setIsDisabled(false);
        //     }
        // }

        /**
         * 第二步，如果必选可见项为空，则不能下一步
         */
        let emptyValueFieldsCount = 0;
        if (typeof formGraphInstance === 'object' && formGraphInstance !== null) {
            Object.keys(formGraphInstance)?.forEach(key => {
                /** key可能为'' */
                if (key) {
                    const fields = formGraphInstance[key];
                    /**
                     * 按钮不可点条件：
                     * 字段可见 &&
                     * 字段为必须项 &&
                     * 有字段值是undefined
                     */
                    if (
                        fields.visible &&
                        fields.required &&
                        (isValueEmpty(fields.values?.[0]) ||
                            (Array.isArray(fields.values?.[0]) &&
                                isValueEmpty(fields.values?.[0]?.[0]))) /** 针对上传文件情况 */
                    ) {
                        emptyValueFieldsCount++;
                    }
                }
            });
            if (emptyValueFieldsCount > 0) {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }
    };
    const renderFooter = () => {
        return (
            <div className={styles.footer}>
                {formData.step_id === 'step_doc_sign' ? (
                    <button
                        type={'submit'}
                        style={{
                            width: '100%',
                            margin: 0,
                        }}
                        className={`${styles.forward} ${isDisabled ? styles.disable : ''}`}
                    >
                        <FormattedMessage id="signDoc.next" />
                    </button>
                ) : (
                    <>
                        <button
                            disabled={!formData.pre_form_id}
                            type="button"
                            onClick={onBackToPreviousPage}
                            className={`${styles.back} ${
                                !formData.pre_form_id ? styles.disable : ''
                            }`}
                        >
                            <FormattedMessage id="previous"></FormattedMessage>
                        </button>

                        <button
                            type={'submit'}
                            className={`${styles.forward} ${isDisabled ? styles.disable : ''}`}
                        >
                            <FormattedMessage id="next"></FormattedMessage>
                        </button>
                    </>
                )}
            </div>
        );
    };

    const renderSkeleton = () => {
        return (
            <Skeleton
                className={styles.skeleton}
                active
                paragraph={{
                    rows: 10,
                    width: [
                        '20%',
                        '100%',
                        '20%',
                        '100%',
                        '20%',
                        '100%',
                        '20%',
                        '100%',
                        '20%',
                        '100%',
                    ],
                    className: styles['skeleton-ul'],
                }}
                title={false}
            />
        );
    };
    console.log(formData.schema);
    return (
        <>
            <StepsWrapper
                donePercent={formData?.progress}
                needBack={!!formData.pre_form_id}
                onClickBack={onBackToPreviousPage}
                showBackButton={!!formData.pre_form_id}
            >
                <div className={styles['basic-info']}>{formData?.basicTitle}</div>
                {ready ? (
                    <SchemaForm
                        components={components}
                        onSubmit={onSubmit}
                        expressionScope={createRichTextUtils(history)}
                        schema={formData.schema}
                        className={styles['form-wrapper']}
                        onChange={onCheckCanClickNextStep}
                        actions={actions}
                        onValidateFailed={onValidateFailed}
                        effects={() => {}}
                    >
                        {renderFooter()}
                    </SchemaForm>
                ) : (
                    renderSkeleton()
                )}
                {/* <SchemaForm
                    components={components}
                    onSubmit={onSubmitTest}
                    expressionScope={createRichTextUtils(history)}
                    schema={schema}
                    onChange={onCheckCanClickNextStep}
                    actions={actions}
                    className={styles['form-wrapper']}
                    onValidateFailed={onValidateFailed}
                    effects={() => {
                        // useLinkageValidateEffects();
                    }}
                >
                    {renderFooter()}
                </SchemaForm> */}
            </StepsWrapper>
            {/* <video
                id="video"
                autoPlay
                muted
                playsInline
                // webkit-playsinline
                style={{
                    width: '0px',
                }}
            ></video> */}
        </>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        setCanUseGetUserMedia: canUseGetUserMedia =>
            dispatch(setCanUseGetUserMedia(canUseGetUserMedia)),
    };
};
export default withRouter(
    connect(state => ({ lang: state.config.lang }), mapDispatchToProps)(Openact),
);
