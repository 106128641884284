import axios from 'axios';
import { setting } from '../config';
import { v4 as uuid } from 'uuid';
import store from '../store/store';
import { showToast } from './toast';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

export const checkIsTokenExisted = () => {
    let hasToken = false;
    const login = JSON.parse(localStorage.getItem('login') || '{}');

    /** if has token */
    if (Object.keys(login).length > 0 && login.token) {
        hasToken = true;
    }

    return {
        hasToken,
    };
};

function Axios() {
    const instance = axios.create({
        // timeout: 50000,
        baseURL: setting.host,
    });

    /** 所有接口都为POST,后续有get需要改配置 */
    const addBasicConfig = config => {
        const login = JSON.parse(localStorage.getItem('login') || '{}');
        const state = store.getState();
        /** add req_id & local data */
        config.data = {
            ...config.data,
            req_id: uuid().slice(0, 16),
            local: {
                client_type: setting.client_type,
                locale: state.config.lang,
                // for test
                // locale: 'en-US',
            },
        };
        /** if has token */
        if (Object.keys(login).length > 0) {
            config.data = {
                ...config.data,
                login: login,
            };
        }
    };

    // backend interface docs
    // https://openact.sharedaka.com/api/swagger-ui/index.html?url=/api/api-docs#/%E7%94%A8%E6%88%B7%E7%9B%B8%E5%85%B3%E7%9A%84%E6%8E%A5%E5%8F%A3/sendCheckCode
    instance.interceptors.request.use(
        config => {
            NProgress.start();
            addBasicConfig(config);
            return config;
        },
        err => {
            return Promise.reject(err);
        },
    );

    instance.interceptors.response.use(
        response => {
            NProgress.done();
            const code = response.data.code;
            if (code === -11 || code === -10 || code === -9) {
                localStorage.removeItem('login');
                window.location.pathname = 'login';

                return Promise.reject(response.data);
            }

            if (code !== 0) {
                return Promise.reject(response.data);
            } else {
                return response.data;
            }
        },
        error => {
            NProgress.done();
            return Promise.reject(error);
        },
    );

    return instance;
}

export default new Axios();
