import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../actions/actions';
import styles from './SwitchLangue.module.scss';
import { LeftOutlined, SwapOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const langList = [
    {
        text: '繁體中文',
        value: 'zh-TW',
    },
    {
        text: '简体中文',
        value: 'zh-CN',
    },
    {
        text: 'English',
        value: 'en-US',
    },
];

const SwitchLangue = ({ showBackButton, onClickBack }) => {
    const lang = useSelector(store => store.config.lang) || 'zh-TW';
    const dispatch = useDispatch();
    const [showSelect, setShowSelect] = useState(false);
    return (
        <div style={{ height: showSelect ? '100%' : null }} className={styles.wrapper}>
            <div className={styles.headerOperation}>
                {showBackButton ? (
                    <button className={styles.back} onClick={onClickBack}>
                        <LeftOutlined />
                        <FormattedMessage id="previous"></FormattedMessage>
                    </button>
                ) : (
                    <div />
                )}
                <button
                    className={styles['switch-lang']}
                    onClick={() => setShowSelect(!showSelect)}
                >
                    {langList.find(item => item.value === lang).text}
                    <SwapOutlined />
                </button>
            </div>
            <div
                style={{ display: showSelect ? 'block' : 'none' }}
                onClick={() => setShowSelect(false)}
                className={styles.mantle}
            >
                <div className={styles['select-list']}>
                    {langList
                        .filter(item => item.value !== lang)
                        .map(item => (
                            <div
                                onClick={() => dispatch(setLang(item.value))}
                                className={styles.option}
                                key={item.value}
                            >
                                {item.text}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SwitchLangue;
