import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'lib-flexible';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes/routes';
import { Provider } from 'react-redux';
import store from './store/store';

import './backupAntd.scss'; // 原生antd
import './antd-mobile.css'; //魔改antd mobile

import 'nprogress/nprogress.css';

/** 注入全局样式 */
import './index.scss';
import LocaleProvider from './locale/localeProvider';
import initWindowVariables from './initWindowVariables';

import { setValidationLocale, setValidationLanguage } from '@formily/antd'; // 或者 @formily/next

setValidationLanguage('zh-TW');

// 给各种语言自定义错误信息。支持动态参数。
setValidationLocale({
    'zh-TW': {
        required: '該信息為必填項，請填寫',
    },
    'zh-CN': {
        required: '该信息为必填项，请填写', //覆盖系统默认的信息
        // required: '<% injectVar %>有误', //覆盖系统默认的信息
        //为自定义的正则类型指定验证不通过的信息。
        // custom_format: '必须是数字  <% injectVar %>', // injectVar为挂在x-rules中的自定义任意变量。
    },
    'en-US': {
        required: 'This information is incorrect',
    },
});
// 自定义函数校验
// registerValidationRules(window.rulesMethods);
// registerValidationMTEngine((message, context) => {
//     return message.replace(/\<\%\s*([\w.]+)\s*\%\>/g, (_, $0) => {
//         return context.injectVar;
//     });
// });

// fuck api ，浪费0.5天时间debug
// registerFormItemComponent(props => {
//     return <FormItem {...props}>{props.children}</FormItem>;
// });

const App = () => {
    useEffect(() => {
        initWindowVariables.init();
    }, []);

    return (
        <Provider store={store}>
            <LocaleProvider>
                <Router>
                    <Routes></Routes>
                </Router>
            </LocaleProvider>
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
