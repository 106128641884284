import React from 'react';
import zh_CN from './zh_CH';
import en_US from './en_US';
import { IntlProvider } from 'react-intl';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setLang } from '../actions/actions';

import { useGetToken } from '../hooks/useGetToken';
import { setValidationLanguage } from '@formily/validator';
import { zh_TW } from './zh_TW';
const LocaleProvider = ({ lang, setLang, children }) => {
    const [ready, setReady] = useState(false);
    /** 放在router里触发会有bug，只好在这里触发 */
    useGetToken();

    let messages = {
        'en-US': en_US,
        // 'en-US': zh_CN, quick switch lang to test.
        'zh-CN': zh_CN,
        'zh-TW': zh_TW,
    };

    const changeLanguage = lang => {
        setLang(lang);
        setReady(true);
    };
    useEffect(() => {
        setValidationLanguage(lang);
    }, [lang]);
    useEffect(() => {
        changeLanguage('zh-TW');
    }, []);

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            {ready && children}
        </IntlProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setLang: lang => dispatch(setLang(lang)),
    };
};

export default connect(state => ({ lang: state.config.lang }), mapDispatchToProps)(LocaleProvider);
