import { useEffect, useRef, useState } from 'react';
import styles from './AgreementDialog.module.scss';
import Dialog from '../Dialog/Dialog';
import { showToast } from '../../utils/toast';
import openact from '../../services/openact';
import { Drawer } from 'antd';
import { useIntl } from 'react-intl';

/** 默认倒计时 */
const TIME_REMAINING = 5000;

export default ({
    onClickAgreementBtn,
    isShowAgreementDialog,
    id,
    closeAgreementDialog,
    nextStepClicked,
}) => {
    const contentRef = useRef(null);
    const [isBtnActive, setIsBtnActive] = useState(false);
    const timer = useRef(null);
    const [timeRemaining, setTimeRemaining] = useState(TIME_REMAINING);
    const [content, setContent] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [title, setTitle] = useState('');
    const intl = useIntl();
    const tip1 = intl.formatMessage({ id: 'AgreementDialog.tip1' });
    const tip2 = intl.formatMessage({ id: 'AgreementDialog.tip2' });
    const tip3 = intl.formatMessage({ id: 'AgreementDialog.tip3' });

    const getAgreement = id => {
        return openact
            .getAgreement({ id: id })
            .then(res => {
                /** 正则去掉style标签，否则动画切换会影响其它页面样式 repaint */
                const reg = /<(style)(?:[^>]*)?>([\s\S]*?)(?:<\/\1>[^\"\']|<\/\1>$)/gi;
                const contentAfterFilteringStyleMarkup = res.data.content
                    .replace(reg, '')
                    .replace('<h2>', '<h4>')
                    .replace('</h2>', '</h4>');
                setContent(content => content + contentAfterFilteringStyleMarkup);
                setTitle(res.data.title);
                setIsReady(true);
                onDialogReady();
            })
            .catch(err => showToast(err.msg));
    };

    useEffect(() => {
        getAgreement(id);
        return () => onDialogUnmounted();
    }, []);

    const __scrollEvent = event => {
        const { scrollHeight, offsetHeight, scrollTop } = event.target;

        // console.log(
        //     `scrollTop=${scrollTop}, offsetHeight=${offsetHeight} > scrollHeight=${scrollHeight}, 等于${
        //         scrollTop + offsetHeight
        //     } `,
        // );

        if (scrollTop + offsetHeight > scrollHeight - 250) {
            __removeScrollEventListener();
            setIsBtnActive(true);
        }
    };

    const __addScrollEventListener = () => {
        contentRef.current.addEventListener('scroll', __scrollEvent);
    };

    const __removeScrollEventListener = () => {
        contentRef.current?.removeEventListener('scroll', __scrollEvent);
    };

    const __startAgreementTimer = () => {
        timer.current = setInterval(() => {
            setTimeRemaining(timeRemaining => {
                if (timeRemaining === 0) {
                    return 0;
                } else {
                    return timeRemaining - 1000;
                }
            });
        }, 1000);
    };

    const __removeAgreementTimer = () => clearInterval(timer.current);

    useEffect(() => {
        if (timeRemaining === 0) {
            __removeAgreementTimer();
        }
    }, [timeRemaining]);

    const onDialogReady = () => {
        __addScrollEventListener();
        if (nextStepClicked) {
            __startAgreementTimer();
        }
    };

    const onDialogUnmounted = () => {
        __removeScrollEventListener();
        __removeAgreementTimer();
    };

    return (
        <>
            {!!isReady && (
                <Drawer
                    placement="bottom"
                    closable={false}
                    visible={isShowAgreementDialog}
                    onClose={nextStepClicked ? undefined : closeAgreementDialog}
                    height={'90%'}
                    bodyStyle={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    className={'bottom-drawer-has-radius'}
                >
                    <h3 className={styles.title}>{title}</h3>

                    <div
                        className={styles.content}
                        ref={contentRef}
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                    {nextStepClicked && (
                        <div className={styles['btn-wrapper']}>
                            <div
                                className={`${styles.btn} ${
                                    isBtnActive && timeRemaining === 0
                                        ? styles['btn-active']
                                        : styles['btn-disable']
                                }`}
                                onClick={() => {
                                    if (timeRemaining === 0 && !isBtnActive) {
                                        return showToast(tip3);
                                    }
                                    if (isBtnActive && timeRemaining === 0) {
                                        onClickAgreementBtn();
                                    }
                                }}
                            >
                                {`${tip1}${
                                    timeRemaining > 0
                                        ? `（${timeRemaining / 1000}s）`
                                        : isBtnActive
                                        ? ''
                                        : tip2
                                }`}
                            </div>
                        </div>
                    )}
                </Drawer>
            )}
        </>
    );
};
