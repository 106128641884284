/**
 * 将upload 上传fields格式进行格式化
 */
const formatUploadFields = (fields = {}) => {
    for (let v in fields) {
        if (Object.prototype.hasOwnProperty.call(fields, v)) {
            if (Array.isArray(fields[v]) && fields[v]?.length > 0 && fields[v][0].url) {
                if (fields[v][0].url) {
                    fields[v] = fields[v].map(v => v.url)?.[0] ?? {};
                }
            }
        }
    }
    return fields;
};
// const formatAutoToUpperCaseFields = fields => {
//     for (let v in fields) {
//         if (Object.prototype.hasOwnProperty.call(fields, v)) {
//             console.log(fields[v]?.props?.['x-component-props']?.autoToUpper);
//         }
//     }
// };

export { formatUploadFields };
