import $ from 'jquery';
/**
 * 强制聚焦第一个校验失败元素
 */
const forceFocusOnFirstValidatingFailedEle = () => {
    const formItem = $('.ant-form-item .ant-form-item-required')?.parent()?.parent();
    const firstBlankRequiredEle = formItem
        ?.find("input[type='text']")
        ?.get()
        ?.find(v => !v.value);

    const firstErrorInputEle = formItem?.find("input[state='error'][type='text']")?.get()?.[0];

    /** value为空 */
    if (firstBlankRequiredEle) {
        firstBlankRequiredEle.focus();
    }
    /** rules error */
    if (firstErrorInputEle) {
        firstErrorInputEle.focus();
    }
};

export { forceFocusOnFirstValidatingFailedEle };
