import { Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './verification.module.scss';
import Header from '../../layouts/header/header';
import misc from '../../services/misc';
import { showToast } from '../../utils/toast';
import openact from '../../services/openact';
import BasicWrapper from '../../components/BasicWrapper/BasicWrapper';
import { connect } from 'react-redux';
import { setHasToken, setLogin } from '../../actions/actions';
import { FormattedMessage } from 'react-intl';
import HeaderLogo from '../../statics/header-logo.png';
import picked from '../../statics/picked.png';
import SwitchLangue from '../../components/SwitchLangue/SwitchLangue';

const Verification = ({ history, match, setLogin }) => {
    const [smsCode, setSmsCode] = useState(null);
    const tel = match.params.tel || 0;
    const countryCode = match.params.countryCode || '';
    const fullTel = `${countryCode}${tel}`;

    const onChangeSmsCode = event => {
        let value = event.target.value;
        if (event.target.value.length > 6) {
            value = value.slice(0, 6);
        }
        setSmsCode(value);
    };
    let timer = null;

    const [retrySmsTime, setRetrySmsTime] = useState(60);

    const __initSmsTimer = () => {
        timer = setTimeout(() => {
            setRetrySmsTime(v => v - 1);
        }, 1000);
    };

    useEffect(() => {
        retrySmsTime !== 0 && __initSmsTimer();
        return () => clearTimeout(timer);
    }, [retrySmsTime]);

    const onClickLogin = () => {
        // setLogin({ login_at: 1615780858025, token: 'a82ad0f', uid: 'ue0187d97c80d09e' });
        // localStorage.setItem(
        //     'login',
        //     JSON.stringify({ login_at: 1615780858025, token: 'a82ad0f', uid: 'ue0187d97c80d09e' }),
        // );
        // history.push(`/start`);
        misc.login({ mobile_number: fullTel, check_code: smsCode })
            .then(res => {
                setLogin(res.data.login);
                setHasToken(true);
                localStorage.setItem('login', JSON.stringify(res.data.login));
                return openact.getStatus();
            })
            .then(res => {
                let targetPath;
                if (res.data.is_newcomer === 1 || res.data.is_completed === 1) {
                    targetPath = `/guide/${countryCode}/${tel}`;
                } else {
                    targetPath = '/schedule';
                }
                misc.checkWhiteList({ mobile_number: fullTel }).then(res => {
                    if (res.data.is_correct) {
                        history.push(targetPath);
                    } else {
                        history.push('/invitation', { targetPath });
                    }
                });
            })
            .catch(err => showToast(err.msg));
    };

    const onClickGetSmsCodeAgain = () => {
        misc.getSmsCode({ mobile_number: fullTel })
            .then(() => {
                setRetrySmsTime(60);
            })
            .catch(err => showToast(err.msg));
    };

    return (
        <BasicWrapper>
            <SwitchLangue />
            <div className={styles.wrapper}>
                <img src={HeaderLogo} className={styles['verifi-logo']} />

                <h1 className={styles['sms-title']}>
                    <FormattedMessage id="verification.tip1"></FormattedMessage>
                </h1>
                <div className={styles['underlane']} />
                <p className={styles['sms-reminder']}>
                    <FormattedMessage id="verification.tip2"></FormattedMessage>
                    {fullTel}
                </p>
                <Input
                    type="number"
                    style={{ textAlign: 'left' }}
                    className={styles['code-input']}
                    onChange={onChangeSmsCode}
                    value={smsCode}
                    suffix={<img src={picked} className={styles['input-icon']} />}
                    maxLength={6}
                ></Input>

                <button
                    className={`${styles['next-step-btn']} ${
                        !smsCode && styles['next-step-btn-disable']
                    }`}
                    onClick={onClickLogin}
                >
                    <FormattedMessage id="next"></FormattedMessage>
                </button>

                <div className={styles['send-code-button']}>
                    {retrySmsTime !== 0 ? (
                        <div className={styles.countdown}>
                            <FormattedMessage
                                id="verification.tip3"
                                values={{ second: retrySmsTime }}
                            ></FormattedMessage>
                        </div>
                    ) : (
                        <div className={styles.resendSms} onClick={onClickGetSmsCodeAgain}>
                            <FormattedMessage id="verification.tip4"></FormattedMessage>
                        </div>
                    )}
                </div>
            </div>
        </BasicWrapper>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setLogin: newLoginData => dispatch(setLogin(newLoginData)),
        setHasToken: hasToken => dispatch(setHasToken(hasToken)),
    };
};
export default withRouter(
    connect(state => ({ lang: state.config.lang }), mapDispatchToProps)(Verification),
);
