import { Drawer } from 'antd';
import { useState, useCallback, useMemo } from 'react';
import styles from './SingleSelector.module.scss';
import REC_ICON from '../../../../statics/select-rectangle.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from '../../../../utils/utils';
import PICKED_ICON from '../../../../statics/picked.png';
import Fuse from 'fuse.js';

export default ({ dataSource, value, onChange, showSearch }) => {
    const [isShowSelector, setIsShowSelector] = useState(false);
    const [selectValue, setSelectValue] = useState(
        () => (value >= 0 && dataSource?.find(v => v.value === value)?.label) || undefined,
    );
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: 'select' });

    const onClickBar = () => {
        setIsShowSelector(true);
    };

    const onCloseSelector = () => {
        __closeSelector();
    };

    const onClickOption = (label, value) => {
        onChange(value);
        setSelectValue(label);
        __closeSelector();
    };

    const __closeSelector = () => setIsShowSelector(false);
    const onConfirm = () => {};
    /*
     *搜索
     */
    const searchPlaceholder = intl.formatMessage({ id: 'search' });
    const [searchKey, setSearchKey] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const fuse = useMemo(
        () =>
            new Fuse(dataSource, {
                includeScore: true,
                keys: ['label', 'value', 'pinyin'],
            }),
        [dataSource],
    );
    const search = useCallback(
        debounce(key => {
            const nextSearchResult = fuse
                .search(key)
                .sort((a, b) => a.item.score - b.item.score)
                .map(v => v.item);
            setSearchResult(nextSearchResult);
        }, 500),
        [dataSource],
    );
    const handleChange = event => {
        const key = event.target.value;
        setSearchKey(key);
        /** fuse match required string */
        search(String(key));
    };
    return (
        <div className={styles['single-select-container']}>
            <div className={styles['select-value']} onClick={onClickBar}>
                <span className={selectValue === undefined ? styles['placeholder'] : ''}>
                    {selectValue === undefined ? placeholder : selectValue}
                </span>
                <img src={REC_ICON} className={styles['select-rec']} />
            </div>
            <Drawer
                placement="bottom"
                closable={false}
                onClose={onCloseSelector}
                visible={isShowSelector}
                height={'10.333333333rem'}
                bodyStyle={{
                    padding: 0,
                    overflow: 'hidden',
                }}
                className={'bottom-drawer-has-radius'}
            >
                <div className={styles['select-container']}>
                    <div className={styles.tips}>
                        <FormattedMessage id="select"></FormattedMessage>
                    </div>
                    {showSearch ? (
                        <>
                            <div className={styles['search-input']}>
                                <div className={styles['input-wrap']}>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        value={searchKey}
                                        placeholder={searchPlaceholder}
                                    />
                                </div>
                            </div>
                            <div className={styles['options']}>
                                {searchResult.length > 0
                                    ? searchResult.map((v, index) => (
                                          <div
                                              key={index}
                                              className={styles['option']}
                                              onClick={() => onClickOption(v.label, v.value)}
                                          >
                                              {v.label}
                                              {value === v.value && (
                                                  <img
                                                      src={PICKED_ICON}
                                                      className={styles['picked']}
                                                  />
                                              )}
                                          </div>
                                      ))
                                    : Array.isArray(dataSource) &&
                                      dataSource.map((v, index) => (
                                          <div
                                              key={index}
                                              className={styles['option']}
                                              onClick={() => onClickOption(v.label, v.value)}
                                          >
                                              {v.label}
                                              {value === v.value && (
                                                  <img
                                                      src={PICKED_ICON}
                                                      className={styles['picked']}
                                                  />
                                              )}
                                          </div>
                                      ))}
                            </div>
                        </>
                    ) : (
                        <div className={styles['options-container']}>
                            {!!dataSource &&
                                dataSource.length > 0 &&
                                dataSource.map((v, index) => (
                                    <div
                                        key={index}
                                        className={styles['option-card']}
                                        onClick={() => onClickOption(v.label, v.value)}
                                    >
                                        {v.label}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};
