import styles from './Dialog.module.scss';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';

export default ({
    onClickOverlay = () => {},
    children,
    ele,
    onReady,
    wrapperStyle = {},
    onUnmounted,
}) => {
    const [modalRootNode, setModalRootNode] = useState(ele);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!modalRootNode) {
            const modalRootNode = document.getElementById('modal-root');
            setModalRootNode(modalRootNode);
        }
        setIsReady(true);

        return () => onUnmounted?.();
    }, []);

    useEffect(() => {
        if (isReady) {
            onReady?.();
        }
        //  else {
        //     onUnmounted();
        // }
    }, [isReady]);

    const Children = (
        <div
            className={styles['confirm-dialog-wrapper']}
            onClick={event => {
                event.stopPropagation();
                onClickOverlay();
            }}
        >
            <div className={styles['confirm-modal']} style={wrapperStyle}>
                {children}
            </div>
        </div>
    );

    return isReady && ReactDOM.createPortal(Children, modalRootNode);
};
