import styles from './CustomRadio.module.scss';
import { useHistory } from 'react-router';
import single_user_info from '../../statics/single-user-info.png';
import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import Dialog from '../../components/Dialog/Dialog';
import { FormattedMessage } from 'react-intl';

/** 客服电话 */
const TEL = 12312;

// terminalValue，会触发弹窗的value值
export default ({ onChange, terminalValue = [], dataSource = [], value, ...rest }) => {
    const [radioValue, setRadioValue] = useState(value);
    const [isShowDialog, setIsShowDialog] = useState(false);

    const onChangeRadioGroup = event => {
        const value = event.target?.value;

        /** 比如为美国公民，就不允许下一步 */
        if (terminalValue.includes(value)) {
            setIsShowDialog(true);
        }

        setRadioValue(value);
        /** 改变schemaForm内部value */
        onChange(value);
    };
    const onClickOverlay = () => {
        __hideDialog();
    };
    const wrapperStyle = {
        position: 'relative',
        backgroundColor: '#fff',
        width: '8.293333333rem',
        overflow: 'hidden',
        borderRadius: '0.266666667rem',
    };

    const __hideDialog = () => {
        setIsShowDialog(false);
    };

    const onClickConfirm = () => {
        __hideDialog();
    };

    return (
        <div className={styles.container}>
            <Radio.Group onChange={onChangeRadioGroup} value={radioValue}>
                {dataSource.map((radio, index) => (
                    <Radio key={index} value={radio.value}>
                        {radio.label}
                    </Radio>
                ))}
            </Radio.Group>

            {isShowDialog && (
                <Dialog onClickOverlay={onClickOverlay} wrapperStyle={wrapperStyle}>
                    <div className={styles['img-wrapper']}>
                        <img className={styles.img} src={single_user_info} />
                    </div>
                    <div className={styles.desc}>
                        <FormattedMessage id="CustomRadio.desc1"></FormattedMessage>
                    </div>
                    <div className={styles.detail}>
                        <FormattedMessage id="CustomRadio.desc2first"></FormattedMessage>
                    </div>
                    <div className={styles.btns}>
                        <div className={styles.left} onClick={onClickConfirm}>
                            <FormattedMessage id="CustomRadio.cancel"></FormattedMessage>
                        </div>
                        {/* <div className={styles.right}>
                            <a href={`tel:${TEL}`} style={{ color: '#fff' }}>
                                <FormattedMessage id="CustomRadio.confirm"></FormattedMessage>
                            </a>
                        </div> */}
                    </div>
                </Dialog>
            )}
        </div>
    );
};
