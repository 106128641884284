import axios from '../utils/o-axios'

class Misc {
    getSmsCode = (data) => {
        return axios.post('/v1/misc/send_cc', {
            data,
        });
    }

    login = (data) => {
        return axios.post('/v1/user/login', {
            data,
        });
    }

    checkWhiteList = (data) => {
        return axios.post('/v1/admin/check_white_list', { data });
    }

    checkInviteCode = (data) => {
        return axios.post('/v1/user/check_invitation_code', { data });
    }
}

export default new Misc()


